import { Layer, Source } from 'react-map-gl/maplibre'
import { rasterLayerProps, rasterSourceProps } from './Shared/RasterLayerCommon'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.SmoothedSeismicity

const SmoothedSeismicityLayer = () => {
  const baseUrl = 'https://site-data.quantectum.com'
  const layerName = 'potentials_smoothed_seismicity'
  const tileUrl = `${baseUrl}/${layerName}.png`

  return (
    <Source key={tileUrl} {...rasterSourceProps(layerType, tileUrl)}>
      <Layer {...rasterLayerProps(layerType)} />
    </Source>
  )
}

export default SmoothedSeismicityLayer
