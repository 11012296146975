import { Tooltip } from '@chakra-ui/react'
import { useMap } from 'react-map-gl/maplibre'

const ResetZoomControl = () => {
  const { current: map } = useMap()

  const handleResetZoomClick = () => {
    if (!map) return
    // TODO: set home location / take center of region, ...
    map.setZoom(1.5)
    map.flyTo({ center: [0, 0] })
    map.resetNorthPitch()
  }

  return (
    <div id='maplibregl-reset-zoom-icon'>
      <Tooltip label='Reset zoom' bg='#333333' fontSize={12} paddingX={2}>
        <button
          onClick={handleResetZoomClick}
          tw='absolute top-[80px] right-2.5 p-[4px] bg-white flex items-center justify-center rounded-md hover:bg-quantectumWhiteGray'
          style={{ boxShadow: '0 0 0 2px rgba(0,0,0,.1)' }}
        >
          <svg
            tw='w-[20px] h-[20px]'
            viewBox='0 0 24 24'
            strokeWidth='2.5'
            stroke='#333333'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <circle cx='10' cy='10' r='7' />
            <line x1='8' y1='8' x2='12' y2='12' />
            <line x1='12' y1='8' x2='8' y2='12' />
            <line x1='21' y1='21' x2='15' y2='15' />
          </svg>
        </button>
      </Tooltip>
    </div>
  )
}

export default ResetZoomControl
