import { ForgotPasswordFormValues } from '../interfaces/ForgotPasswordFormValues'
import { fisApiInstance } from '../http'
import { ConfirmAccountRequest } from '../interfaces/ConfirmAccountRequest'
import { LoginData } from '../interfaces/LoginData'
import { ResetPasswordRequest } from '../interfaces/ResetPasswordRequest'

export const login = async (loginData: LoginData) => {
  return await fisApiInstance.post<LoginData, any>('/login', loginData)
}

export const forgotPassword = async (forgotPasswordFormValues: ForgotPasswordFormValues) => {
  return await fisApiInstance.post<ForgotPasswordFormValues, any>(
    '/forgot-password',
    forgotPasswordFormValues,
  )
}

export const resetPassword = async (resetPasswordRequest: ResetPasswordRequest) => {
  return await fisApiInstance.post<ResetPasswordRequest, any>(
    '/reset-password',
    resetPasswordRequest,
  )
}

export const confirmAccount = async (
  confirmAccountRequest: ConfirmAccountRequest,
  userId: string,
) => {
  return await fisApiInstance.put<ConfirmAccountRequest, any>(
    `/confirm-account/${userId}`,
    confirmAccountRequest,
  )
}
