import { gisApiBinaryInstance } from '../http'
import { Earthquake } from '../interfaces/Earthquake'
import moment from 'moment'

export const fetchReportToDataUrl = async (earthquake: Earthquake, reportName: string) => {
  const url = `/${moment
    .utc(earthquake.datetime, 'YYYY-MM-DD HH:mm:ss')
    .format('YYYYMMDD_HHmmss')}_${earthquake.latitude.toFixed(1)}_${earthquake.longitude.toFixed(
    1,
  )}_${earthquake.magnitude.toFixed(1)}_${reportName}.pdf`

  const response = await gisApiBinaryInstance.get<any>(url, {
    baseURL: 'https://site-data.quantectum.com',
  })

  if (response.status >= 400) {
    throw new Error(`Error fetching report: ${response.status} - ${response.statusText}}`)
  }

  const blob = new Blob([new Uint8Array(response.data)], { type: 'application/pdf' })
  return URL.createObjectURL(blob)
}
