import ForgotPasswordForm from '../components/Auth/ForgotPasswordForm'

const ForgotPasswordPage = () => {
  return (
    <div tw='bg-white w-[460px] h-auto'>
      <div tw='p-[50px]'>
        <ForgotPasswordForm />
      </div>
    </div>
  )
}

export default ForgotPasswordPage
