import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ForgotPasswordFormValues } from '../lib/interfaces/ForgotPasswordFormValues'
import useAuthStore from '../state/Auth/AuthStore'
import { useTranslationFunction } from './useTranslationFunction'

export const useForgotPasswordState = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { forgotPassword } = useAuthStore()
  const toast = useToast()
  const navigate = useNavigate()
  const { t } = useTranslationFunction()

  const handleBackClick = () => {
    navigate('/login')
  }

  const handleOnSubmit = async (formData: ForgotPasswordFormValues, redirectToLogin = false) => {
    setIsLoading(true)
    await forgotPassword(formData)
    setIsLoading(false)

    if (redirectToLogin) {
      navigate('/login')
    }

    toast({
      title: t('Auth.ForgotPasswordForm.Toast.Title'),
      description: t('Auth.ForgotPasswordForm.Toast.Description'),
      status: 'success',
      duration: 6000,
      isClosable: true,
    })
  }

  return {
    isLoading,
    handleBackClick,
    handleOnSubmit,
    t,
  }
}
