import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { SearchInputType } from '../../../lib/types/SearchInputType'
import useAlertsNotificationsStore from '../../../state/AlertsNotifications/AlertsNotificationsStore'
import SearchInput from '../shared/SearchInput'
import AlertsNotificationsList from './AlertsNotificationsList'
import AlertsNotificationsSortSelect from './AlertsNotificationsSortSelect'

const AlertsNotificationsSidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useAlertsNotificationsStore()
  const { t } = useTranslationFunction()

  return (
    <Drawer isOpen={isSidebarOpen} placement='right' onClose={toggleSidebar} size='sm'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <div tw='mt-4 text-xl'>{t('AlertNotification.Sidebar.Title')}</div>
        </DrawerHeader>
        <DrawerBody>
          <div tw='mt-4'>
            <SearchInput usedBy={SearchInputType.AlertNotification} />
          </div>
          <div tw='mt-4'>
            <AlertsNotificationsSortSelect />
          </div>
          <div tw='mt-4'>
            <AlertsNotificationsList />
          </div>
        </DrawerBody>
        <DrawerFooter></DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default AlertsNotificationsSidebar
