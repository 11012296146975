import moment from 'moment'
import { gisApiInstance } from '../http'

export const fetchMaxHorizontalStress = async (date: Date) => {
  const dateFormatted = moment(date, 'DD MM YYYY').format(moment.HTML5_FMT.DATE)
  const stressResolution = 2 // 1 to 5 degrees
  // TODO: we need to add an API endpoint and add dateFormatted param in url additionally.
  return await gisApiInstance.get<any>(
    `https://gis.quantectum.com/stress/current/Max Horizontal Stress ${stressResolution}_563.geojson`,
  )
}
