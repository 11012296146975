import { Layer, Source } from 'react-map-gl/maplibre'
import useDateStore from '../../../state/Date/DateStore'
import moment from 'moment'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { rasterLayerProps, rasterSourceProps } from './Shared/RasterLayerCommon'

const layerType = MapLayerType.TectonicTraction

const TectonicTractionLayer = () => {
  const { date } = useDateStore()

  const baseUrl = 'https://site-data.quantectum.com'
  const layerName = 'dynamic_mean(STn,DTn)'
  const dateStr = moment(date).subtract(3, 'days').format('YYYYMMDD')
  const tileUrl = `${baseUrl}/${dateStr}_${layerName}.png`

  return (
    <Source key={tileUrl} {...rasterSourceProps(layerType, tileUrl)}>
      <Layer {...rasterLayerProps(layerType)} />
    </Source>
  )
}

export default TectonicTractionLayer
