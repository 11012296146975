import { useEffect, useState } from 'react'
import { FillLayer, Layer, LayerProps, LineLayer, Source } from 'react-map-gl/maplibre'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import { fetchMacroseismicIntensity } from '../../../lib/services/macroseismic-intensity.service'
import { splitFeaturesForMacroseismicIntensity, emptyGeoJson, FeatureCollection } from '../utils'
import type GeoJSON from 'geojson'
import SunCalc from 'suncalc'
import moment from 'moment'

const layerType = MapLayerType.Nightshade

const layerProps: FillLayer = {
  id: layerType,
  source: layerType,
  type: 'fill',
  paint: {
    'fill-color': '#000000',
    'fill-opacity': 0.1,
  },
}

function getNightSideGeoJSON(time: Date, minLon: number, maxLon: number): GeoJSON.Feature {
  const coords: number[][] = []
  const lastLonOffset = 0.01 // Workaround: some overlap is visible because of inaccuracy, this reduces it

  // Loop through latitudes and longitudes to find terminator line
  const [minLat, maxLat] = [-90, 90]
  for (let lon = minLon; lon <= maxLon; lon += 6) {
    let bestLat = minLat
    let bestAltitude = Number.MAX_VALUE
    let sunPos = SunCalc.getPosition(time, minLat, lon)
    const sign = Math.sign(sunPos.altitude)

    for (let lat = minLat; lat <= maxLat; lat += 0.01) {
      sunPos = SunCalc.getPosition(time, lat, lon)
      if (Math.abs(sunPos.altitude) < bestAltitude) {
        bestAltitude = sunPos.altitude
        bestLat = lat
      }

      const currentSign = Math.sign(sunPos.altitude)
      if (sign !== currentSign) break
    }
    coords.push([lon, bestLat])
  }
  coords.unshift([minLon, 90])
  coords.push([maxLon - lastLonOffset, 90])

  // Polygon should now represent the night side without overlapping itself
  return {
    type: 'Feature',
    properties: { name: 'Night Side' },
    geometry: {
      type: 'Polygon',
      coordinates: [coords],
    },
  }
}

const NightshadeLayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  // Workaround: two polygons, since there are visual glitches if there's only one
  const [nightshade1, setNightshade1] = useState<GeoJSON.Polygon | GeoJSON.Feature | null>(null)
  const [nightshade2, setNightshade2] = useState<GeoJSON.Polygon | GeoJSON.Feature | null>(null)
  const { selected } = useEarthquakesStore()

  useEffect(() => {
    const unsetData = () => {
      setNightshade1(null)
      setNightshade2(null)
      setIsLoading(false)
    }

    try {
      const date = selected ? moment.utc(selected.datetime).toDate() : new Date()
      setNightshade1(getNightSideGeoJSON(date, -180, 0))
      setNightshade2(getNightSideGeoJSON(date, 0, 180))
    } catch (error) {
      console.error(error)
      unsetData()
    }

    return () => {
      unsetData()
    }
  }, [selected])

  if (isLoading) {
    return null
  }

  return (
    <>
      <Source type='geojson' data={nightshade1 ?? emptyGeoJson}>
        <Layer {...layerProps} />
      </Source>
      <Source type='geojson' data={nightshade2 ?? emptyGeoJson}>
        <Layer {...layerProps} id={MapLayerType.Nightshade + '2'} />
      </Source>
    </>
  )
}

export default NightshadeLayer
