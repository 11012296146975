import React from 'react'
import { Earthquake } from '../../../lib/interfaces/Earthquake'
import EarthquakeMagnitudeBox from './EarthquakeMagnitudeBox'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

const EarthquakeMagnitudeFilter = () => {
  const { minMagnitude, setMinMagnitude } = useEarthquakesStore()
  const { t } = useTranslation()

  const fakeEarthquakes = [
    {
      magnitude: 3.5,
      color: 'rgb(0, 255, 0)',
    },
    {
      magnitude: 4.0,
      color: 'rgb(0, 255, 0)',
    },
    {
      magnitude: 4.5,
      color: 'rgb(149, 255, 0)',
    },
    {
      magnitude: 5.0,
      color: 'rgb(255, 255, 0)',
    },
    {
      magnitude: 5.5,
      color: 'rgb(255, 215, 0)',
    },
    {
      magnitude: 6.0,
      color: 'rgb(255, 172, 0)',
    },
  ] as Earthquake[]

  const twClasses = (eq: Earthquake) => {
    const isSelected = minMagnitude === eq.magnitude
    return isSelected
      ? tw`border border-r-2 border-gray-800 rounded-md select-none`
      : tw`border border-r-2 border-transparent rounded-md select-none cursor-pointer`
  }

  return (
    <div tw='flex flex-row justify-between px-4 border border-gray-200 py-1'>
      <div tw='my-auto'>{t('Earthquakes.MinMagnitude')}</div>
      {fakeEarthquakes.map((eq) => (
        <div css={twClasses(eq)} key={eq.magnitude} onClick={() => setMinMagnitude(eq.magnitude)}>
          <EarthquakeMagnitudeBox key={eq.magnitude} earthquake={eq} />
        </div>
      ))}
    </div>
  )
}

export default EarthquakeMagnitudeFilter
