import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { defaultsDataState } from '../../lib/config/defaults-data-state'
import { AlertNotification } from '../../lib/interfaces/AlertNotification'
import { BaseDataState } from '../../lib/interfaces/BaseDataState'
import { Sortable } from '../../lib/interfaces/Sortable'
import { fetchAlertsNotifications } from '../../lib/services/alert-notification.service'
import { search } from '../../lib/services/search.service'
import { sortByType } from '../../lib/services/sorting.service'
import { SortType } from '../../lib/types/SortType'
import {
  handleStateAfterSuccessfulHttpRequest,
  handleStateAfterUnsuccessfulHttpRequest,
  handleStateBeforeHttpRequest,
} from '../state-helper'

interface AlertsNotificationsState extends BaseDataState<AlertNotification> {
  dataOriginal: AlertNotification[]
  isSidebarOpen: boolean
  sortType: SortType | string
  toggleSidebar: () => void
  getAll: (firstDate: Date, lastDate: Date) => void
  sort: (sortType: SortType) => void
  search: (searchTerm: string) => void
  reset: () => void
}

const initialState = {
  ...defaultsDataState,
  dataOriginal: [],
  isSidebarOpen: false,
  sortType: '',
}

const useAlertsNotificationsStore = create<AlertsNotificationsState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      toggleSidebar: () => {
        const isSidebarOpen = get().isSidebarOpen
        set(() => ({
          isSidebarOpen: !isSidebarOpen,
        }))
      },
      getAll: async (firstDate: Date, lastDate: Date) => {
        handleStateBeforeHttpRequest(set)

        try {
          const response = await fetchAlertsNotifications(firstDate, lastDate)
          handleStateAfterSuccessfulHttpRequest(set)
          set({ data: response.data })
          set({ dataOriginal: response.data })
        } catch (error) {
          handleStateAfterUnsuccessfulHttpRequest(set)
        }
      },
      sort: (sortType: SortType | string) => {
        if (sortType === '') {
          set({ data: get().dataOriginal })
        } else {
          const alertsNotificationsSorted = sortByType(get().data, sortType as SortType)
          set({ data: alertsNotificationsSorted as AlertNotification[] })
        }
        set({ sortType: sortType })
      },
      search: (searchTerm: string) => {
        if (searchTerm !== '') {
          const searchResult = search(get().dataOriginal, searchTerm)
          const searchResultSorted = sortByType(
            searchResult as Sortable[],
            get().sortType as SortType,
          )
          set({ data: searchResultSorted as AlertNotification[] })
        } else {
          if (get().sortType === '') {
            set({ data: get().dataOriginal })
          } else {
            const alertsNotificationsSorted = sortByType(
              get().dataOriginal,
              get().sortType as SortType,
            )
            set({ data: alertsNotificationsSorted as AlertNotification[] })
          }
        }
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: 'alerts-notifications-storage',
    },
  ),
)

export default useAlertsNotificationsStore
