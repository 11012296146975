import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import useAuthStore from '../../state/Auth/AuthStore'
import { QuantectumButtonStyles } from '../../styles/shared/QuantectumButtonStyles'
import LoadingFeedback from '../Shared/LoadingFeedback'

interface LoginFormValues {
  readonly email: string
  readonly password: string
}

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslationFunction()
  const toast = useToast()
  const navigate = useNavigate()
  const { login } = useAuthStore()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  const handleShowPasswordClick = () => setShowPassword(!showPassword)

  const onSubmit: SubmitHandler<LoginFormValues> = async (formData: LoginFormValues) => {
    setIsLoading(true)
    const success = await login(formData.email, formData.password)
    setIsLoading(false)

    if (success) {
      navigate('/')
    } else {
      toast({
        title: t('Auth.LoginForm.Toast.Error.Title'),
        description: t('Auth.LoginForm.Toast.Error.Description'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <form tw='relative' onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback isLoading={isLoading}>
        {() => {
          return (
            <>
              <div tw='text-center text-[18px] font-bold mb-[24px]'>
                {t('Auth.LoginForm.Title')}
              </div>
              <FormControl id='email' isInvalid={errors.email !== undefined}>
                <FormLabel fontWeight='normal'>{t('Auth.LoginForm.EmailFormLabel')}</FormLabel>
                <Input type='email' {...register('email', { required: true })} />
                <FormErrorMessage>{t('Auth.LoginForm.EmailRequiredMessage')}</FormErrorMessage>
              </FormControl>
              <FormControl id='password' isInvalid={errors.password !== undefined} mt={4}>
                <FormLabel fontWeight='normal'>{t('Auth.LoginForm.PasswordFormLabel')}</FormLabel>
                <InputGroup size='md'>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', { required: true })}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.5rem' size='sm' onClick={handleShowPasswordClick}>
                      {showPassword
                        ? t('Auth.Shared.HidePasswordText')
                        : t('Auth.Shared.ShowPasswordText')}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{t('Auth.LoginForm.PasswordRequiredMessage')}</FormErrorMessage>
              </FormControl>
              <Link to={'/forgotPassword'}>
                <div tw='text-[12px] text-right mt-2 underline'>
                  {t('Auth.LoginForm.ForgotPasswordLinkText')}
                </div>
              </Link>
              <div tw='mt-8'>
                <Button
                  {...QuantectumButtonStyles}
                  disabled={!isValid}
                  type='submit'
                  w='full'
                  py={7}
                >
                  {t('Auth.LoginForm.SignInButtonText')}
                </Button>
              </div>
              <Link to={'/'}>
                <div tw='w-full font-bold mt-4 flex justify-center items-center'>
                  {t('Auth.LoginForm.ContinueAsFreeUser')}
                </div>
              </Link>
            </>
          )
        }}
      </LoadingFeedback>
    </form>
  )
}

export default LoginForm
