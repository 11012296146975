import { Spinner } from '@chakra-ui/react'

const LoadingSpinner = () => {
  return (
    <div tw='flex w-full h-full justify-center'>
      <Spinner color='blue' size='lg' />
    </div>
  )
}

export default LoadingSpinner
