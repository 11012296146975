import { Outlet } from 'react-router-dom'
import AppBar from '../Header/AppBar'

const AppLayout = () => {
  return (
    <div tw='flex flex-col h-screen'>
      <AppBar />
      <div tw='flex flex-grow'>
        <Outlet />
      </div>
    </div>
  )
}

export default AppLayout
