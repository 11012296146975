import axios, { InternalAxiosRequestConfig } from 'axios'

const jsonHeaders: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const pdfHeaders: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/pdf',
}

export const fisApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: jsonHeaders,
})

fisApiInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> =>
    fisApiRequestHandler(request),
  (error) => handleError(error),
)
fisApiInstance.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
)

const fisApiRequestHandler = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const jwtToken = localStorage.getItem('jwtToken')
  request.headers = request.headers || {}
  if (jwtToken) {
    request.headers['Authorization'] = `Bearer ${jwtToken}`
  }
  return request
}

export const gisApiInstance = axios.create({
  baseURL: process.env.REACT_APP_GIS_API_BASE_URL,
  headers: jsonHeaders,
})

gisApiInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> =>
    gisApiRequestHandler(request),
  (error) => handleError(error),
)
gisApiInstance.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
)

export const gisApiBinaryInstance = axios.create({
  baseURL: process.env.REACT_APP_GIS_API_BASE_URL,
  headers: pdfHeaders,
  responseType: 'arraybuffer',
})

gisApiBinaryInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> =>
    gisApiRequestHandler(request),
  (error) => handleError(error),
)
gisApiBinaryInstance.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
)

const gisApiRequestHandler = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const authorization =
    'Basic ' +
    window.btoa(
      process.env.REACT_APP_GIS_API_USERNAME + ':' + process.env.REACT_APP_GIS_API_PASSWORD,
    )
  request.headers = request.headers || {}
  request.headers['Authorization'] = authorization
  return request
}

export const gisFetcher = (url: string) => gisApiInstance.get(url).then((res) => res.data)

const handleError = (error: any) => {
  return Promise.reject(error)
}
