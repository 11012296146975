import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import useAlertsNotificationsStore from '../../state/AlertsNotifications/AlertsNotificationsStore'
import useEarthquakesStore from '../../state/Earthquakes/EarthquakesStore'
import useAuthStore from '../../state/Auth/AuthStore'
import useDateStore from '../../state/Date/DateStore'
import useReportsStore from '../../state/Reports/ReportsStore'
import DotsVerticalIcon from './DotsVerticalIcon'
import FreeFeature from '../Shared/FreeFeature'
import NonFreeFeature from '../Shared/NonFreeFeature'

const HeaderMenu = () => {
  // TODO: can we tag all stores, and clear all, or have a compiler error if not
  const { currentUser, logout, reset: resetAuthStore } = useAuthStore()
  const { reset: resetReportsStore } = useReportsStore()
  const { reset: resetAlertsNotificationsStore } = useAlertsNotificationsStore()
  const { reset: resetEarthquakesStore } = useEarthquakesStore()
  const { reset: resetDateStore } = useDateStore()
  const navigate = useNavigate()
  const { t } = useTranslationFunction()

  const handleProfileClick = () => {
    navigate('/profile')
  }

  const handleLogoutClick = () => {
    logout()
    resetAuthStore()
    resetReportsStore()
    resetAlertsNotificationsStore()
    resetEarthquakesStore()
    resetDateStore()
    navigate('/login')
  }

  const handleLoginClick = () => {
    navigate('/login')
  }

  return (
    <>
      <FreeFeature>
        <div tw='text-white whitespace-nowrap mx-5'>
          {t('Header.HeaderMenu.LoginMenuFreeUser')}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <button onClick={handleLoginClick}>{t('Header.HeaderMenu.LoginMenuEntry')}</button>
        </div>
      </FreeFeature>
      <NonFreeFeature>
        <div tw='text-white whitespace-nowrap'>{currentUser?.email}</div>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<DotsVerticalIcon />}
            aria-label='Menu options'
            background={'transparent'}
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          />
          <MenuList
            mt={2}
            zIndex={1500}
            boxShadow='xl'
            borderLeft='2px solid #333'
            borderLeftRadius='4px'
          >
            <MenuItem onClick={handleProfileClick}>
              {t('Header.HeaderMenu.ProfileMenuEntry')}
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>
              {t('Header.HeaderMenu.LogoutMenuEntry')}
            </MenuItem>
          </MenuList>
        </Menu>
      </NonFreeFeature>
    </>
  )
}

export default HeaderMenu
