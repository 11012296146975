import { decodeToken } from 'react-jwt'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { ConfirmAccountRequest } from '../../lib/interfaces/ConfirmAccountRequest'
import { ForgotPasswordFormValues } from '../../lib/interfaces/ForgotPasswordFormValues'
import { User } from '../../lib/interfaces/User'
import {
  confirmAccount,
  forgotPassword,
  login,
  resetPassword,
} from '../../lib/services/auth.service'
import { ResetPasswordRequest } from '../../lib/interfaces/ResetPasswordRequest'

interface AuthStore {
  currentUser: User | null // Note: null means free user for now
  jwtToken: string | null
  login: (email: string, password: string) => Promise<boolean>
  logout: () => void
  forgotPassword: (forgotPasswordFormValues: ForgotPasswordFormValues) => Promise<boolean>
  resetPassword: (resetPasswordRequest: ResetPasswordRequest) => Promise<boolean>
  confirmAccount: (confirmAccountRequest: ConfirmAccountRequest, userId: string) => Promise<boolean>
  reset: () => void
  isFreeUser: () => boolean
}

const initialState = {
  currentUser: null,
  jwtToken: null,
}

const useAuthStore = create<AuthStore>()(
  persist(
    devtools(
      (set, get) => ({
        ...initialState,
        login: async (email: string, password: string) => {
          // FIXME: temporary auth bypass - fake auth data, delete this, see also: ProtectedRoute
          if (
            (email.endsWith('@quantectum.com') && password.length >= 8) ||
            (email === 'cc196@bmkg.go.id' && password === 'D4UQzQ6d8g')
          ) {
            const token = 'YepThatIsAToken'
            set({ jwtToken: token })
            localStorage.setItem('jwtToken', token)
            set({
              currentUser: {
                id: 1,
                firstname: 'firstname',
                lastname: 'lastname',
                company: 'company',
                address: 'address',
                email: email,
                regions: [],
                type: '',
              },
            })
            return true
          } else {
            return false
          }
          // FIXME ^
          try {
            const response = await login({ email, password })
            set({ jwtToken: response.data.token })
            localStorage.setItem('jwtToken', response.data.token)
            const myDecodedToken: any = decodeToken(response.data.token)
            set({
              currentUser: {
                id: myDecodedToken.id,
                firstname: myDecodedToken.firstname,
                lastname: myDecodedToken.lastname,
                company: myDecodedToken.company,
                address: myDecodedToken.address,
                email: myDecodedToken.email,
                regions: myDecodedToken.regions,
                type: myDecodedToken.type,
              },
            })
            return true
          } catch (error) {
            return false
          }
        },
        logout: () => {
          set({ currentUser: null, jwtToken: null })
          localStorage.removeItem('jwtToken')
        },
        forgotPassword: async (forgotPasswordFormValues: ForgotPasswordFormValues) => {
          try {
            await forgotPassword(forgotPasswordFormValues)
            return true
          } catch (error) {
            return false
          }
        },
        resetPassword: async (resetPasswordRequest: ResetPasswordRequest) => {
          try {
            await resetPassword(resetPasswordRequest)
            return true
          } catch (error) {
            return false
          }
        },
        confirmAccount: async (confirmAccountRequest: ConfirmAccountRequest, userId: string) => {
          try {
            await confirmAccount(confirmAccountRequest, userId)
            return true
          } catch (error) {
            return false
          }
        },
        reset: () => {
          set(initialState)
        },
        isFreeUser: () => {
          return get().currentUser === null
        },
      }),
      {
        name: 'auth-storage',
      },
    ),
    {
      name: 'auth-storage',
    },
  ),
)

export default useAuthStore
