import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './i18n/config'
import { customChakraTheme } from './styles/ChakraTheme'
import FontStyles from './styles/FontStyles'
import GlobalStyles from './styles/GlobalStyles'
import { MapProvider } from 'react-map-gl/maplibre'

const container = document.getElementById('root')
const root = createRoot(container as Element)

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <FontStyles />
    <ChakraProvider theme={customChakraTheme}>
      <Router>
        <MapProvider>
          <App />
        </MapProvider>
      </Router>
    </ChakraProvider>
  </React.StrictMode>,
)
