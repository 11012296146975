import { fisApiInstance } from '../http'
import moment from 'moment'
import { Report } from '../interfaces/Report'

export const fetchReports = async (firstDate: Date, lastDate: Date) => {
  const firstDateFormatted = moment(firstDate).format(moment.HTML5_FMT.DATE)
  const lastDateFormatted = moment(lastDate).format(moment.HTML5_FMT.DATE)

  return await fisApiInstance.get<Report[]>(
    `/reports/all-for-customer?dateFrom=${firstDateFormatted}&dateTo=${lastDateFormatted}`,
  )
}

export const downloadReport = async (id: number) => {
  return await fisApiInstance.get<any>(`/reports/download/${id.toString()}`, {
    responseType: 'blob',
  })
}
