import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { downloadReport as downloadReportFromService } from '../lib/services/old.report.service'
import { useTranslationFunction } from './useTranslationFunction'

export const useReportDownload = () => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslationFunction()

  const downloadReport = async (id: number, name: string) => {
    try {
      setIsLoading(true)
      const response = await downloadReportFromService(id)
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = name
      a.click()
      a.remove()
      setTimeout(() => window.URL.revokeObjectURL(url), 100)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast({
        title: t('Reports.Sidebar.DownloadError.Title'),
        description: t('Reports.Sidebar.DownloadError.Description'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return {
    isLoading,
    downloadReport,
  }
}
