import { IconButton } from '@chakra-ui/react'
import tw, { styled, TwStyle } from 'twin.macro'
import ArrowDownIconImage from '../../../assets/images/arrow-down-icon.png'
import ArrowDoubleDownIconImage from '../../../assets/images/arrow-double-down-icon.png'

type ArrowButtonIconVariant = 'left' | 'right'

interface ArrowButtonProps {
  onClickHandler: () => void
  variant: ArrowButtonIconVariant
  double?: boolean
  title: string
}

interface ArrowButtonIconProps {
  variant: ArrowButtonIconVariant
}

const arrowButtonIconVariants: Record<ArrowButtonIconVariant, TwStyle> = {
  left: tw`rotate-90`,
  right: tw`-rotate-90`,
}

const ArrowButtonIcon = styled.img<ArrowButtonIconProps>(() => [
  ({ variant }) => arrowButtonIconVariants[variant],
])

const ArrowButton = ({ onClickHandler, variant, double = false, title }: ArrowButtonProps) => {
  return (
    <IconButton
      icon={
        <ArrowButtonIcon
          src={double ? ArrowDoubleDownIconImage : ArrowDownIconImage}
          variant={variant}
        />
      }
      onClick={onClickHandler}
      title={title}
      aria-label='Change date'
      background={'transparent'}
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
      minWidth={5}
    />
  )
}

export default ArrowButton
