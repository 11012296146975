import { Layer, LayerProps, Source } from 'react-map-gl/maplibre'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import { earthquakesToGeoJSON } from '../utils'
import useNamedEventHandler from '../../../hooks/useNamedEventHandler'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.Earthquakes

const layerProps: LayerProps = {
  id: layerType,
  type: 'circle',
  paint: {
    'circle-radius': ['get', 'radius'],
    'circle-color': ['get', 'color'],
    'circle-stroke-width': 1.75,
  },
}

const EarthquakesLayer = () => {
  const { data: earthquakes, loading, toggleSidebar, select, provider } = useEarthquakesStore()

  useNamedEventHandler(layerProps.id as string, (feature) => {
    if (feature) {
      select(feature.properties.id)
      toggleSidebar(true)
    } else {
      select(undefined)
    }
  })

  if (loading) {
    return null
  }

  return (
    <Source
      type='geojson'
      attribution={`Earthquakes © ${provider}`}
      data={earthquakesToGeoJSON(earthquakes)}
    >
      <Layer {...layerProps} />
    </Source>
  )
}

export default EarthquakesLayer
