import { Layer, LayerProps, Source } from 'react-map-gl/maplibre'
import { circleRadiusKm, loadMapImages, formatNumberWithUnit } from '../utils'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { useEffect, useState } from 'react'
import GeoJSON from 'geojson'
import useMapStore from '../../../state/Map/MapStore'
import { MapLayerMouseEvent } from 'maplibre-gl'
import Papa from 'papaparse'
import useAuthStore from '../../../state/Auth/AuthStore'

// Note: almost everything is inside the Layer file, since this is temporary

const layerType = MapLayerType.CountryStats

const layerProps: LayerProps = {
  id: layerType,
  type: 'symbol',
  layout: {
    'icon-image': 'statistics',
    // TODO: write the fancy scaler in utils 'icon-size': ['get', 'icon-size'],
    'icon-size': circleRadiusKm(0.5, 1, 5),
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 0.75,
  },
}

const csvToGeoJson = (csvString: string): GeoJSON.FeatureCollection => {
  const parseResult = Papa.parse(csvString, {
    header: true,
    dynamicTyping: true,
  })

  const features = parseResult.data
    .filter((row: any) => row['Country Name'] && (row['Latitude'] || row['Latitude']))
    .map((row: any) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [row['Longitude'], row['Latitude']],
        },
        properties: { ...row },
      } as GeoJSON.Feature
    })
  console.log(features)

  return {
    type: 'FeatureCollection',
    features,
  }
}

export const fetchCountryStats = async () => {
  return (await fetch('temp/Development_Indicators_latlon.csv')).text()
}

const CountryStatsLayer = () => {
  const [countryStats, setCountryStats] = useState<GeoJSON.FeatureCollection | null>(null)
  const { map, setPopupInfo } = useMapStore()
  const { isFreeUser } = useAuthStore()

  if (isFreeUser()) {
    return null
  }

  // TODO: attribution
  const attribution = ''

  useEffect(() => {
    if (!map) return

    const fetchAll = async () => {
      const response = await fetchCountryStats()
      // https://www.flaticon.com/free-icon/statistics_432548
      await loadMapImages(map, { statistics: 'temp/statistics.png' })
      setCountryStats(csvToGeoJson(response))
    }
    setTimeout(fetchAll, 1)

    const onClickPopup = (event: MapLayerMouseEvent) => {
      if (!event.features) return

      const feature = { ...event.features[0].properties }
      const population = feature['Population'] ? formatNumberWithUnit(feature['Population']) : '?'
      const gdp = feature['GDP per Capita']
        ? '$' + formatNumberWithUnit(feature['GDP per Capita'], 2)
        : '?'
      const internetUsage = feature['Individuals using the Internet']
        ? formatNumberWithUnit(feature['Individuals using the Internet'])
        : '?'
      const appUsers = feature['Potential Omega APP users (1% of internet 25+ users))']
        ? formatNumberWithUnit(feature['Potential Omega APP users (1% of internet 25+ users))'])
        : '?'
      const appRevenue = feature['Revenue from Omega APP users at 9,99$/m']
        ? '$' + formatNumberWithUnit(feature['Revenue from Omega APP users at 9,99$/m'], 2)
        : '?'

      const content = (
        <div tw='p-4'>
          <div tw='text-lg text-center mb-4'>
            <b>{feature['Country Name']}</b>
          </div>
          <div>Population: {population}</div>
          <div>Internet users: {internetUsage}</div>
          <div>GDP per Capita: {gdp} </div>
          <div>Potential app users: {appUsers}</div>
          <div>Revenue at $9.99/month: {appRevenue}</div>
        </div>
      )
      setPopupInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        content,
      })
    }
    map.on('click', layerType, onClickPopup)
    return () => {
      map.off('click', layerType, onClickPopup)
    }
  }, [map])

  return (
    <Source type='geojson' cluster={false} attribution={attribution} data={countryStats}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default CountryStatsLayer
