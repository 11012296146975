import { createGlobalStyle } from 'styled-components'
import ApercuProBlackTtf from '../fonts/ApercuPro-Black.ttf'
import ApercuProBlackWoff from '../fonts/ApercuPro-Black.woff'
import ApercuProBlackWoff2 from '../fonts/ApercuPro-Black.woff2'
import ApercuProMediumTtf from '../fonts/ApercuPro-Medium.ttf'
import ApercuProMediumWoff from '../fonts/ApercuPro-Medium.woff'
import ApercuProMediumWoff2 from '../fonts/ApercuPro-Medium.woff2'
import ApercuProRegularTtf from '../fonts/ApercuPro-Regular.ttf'
import ApercuProRegularWoff from '../fonts/ApercuPro-Regular.woff'
import ApercuProRegularWoff2 from '../fonts/ApercuPro-Regular.woff2'

const FontStyles = createGlobalStyle`
   @font-face {
    font-family: 'ApercuPro';
    src: url(${ApercuProRegularWoff}) format('woff2'),
          url(${ApercuProRegularWoff2}) format('woff'),
          url(${ApercuProRegularTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
    }

    @font-face {
      font-family: 'ApercuPro';
      src: url(${ApercuProMediumWoff2}) format('woff2'),
            url(${ApercuProMediumWoff}) format('woff'),
            url(${ApercuProMediumTtf}) format('truetype');
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: 'ApercuPro';
      src: url(${ApercuProBlackWoff2}) format('woff2'),
            url(${ApercuProBlackWoff}) format('woff'),
            url(${ApercuProBlackTtf}) format('truetype');
      font-weight: 800;
      font-style: normal;
    }
`

export default FontStyles
