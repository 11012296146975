import { MapLayerType } from '../../../lib/types/MapLayerType'
import MapLayerToggle from './MapLayerToggle'
import MapLayerGroupToggle from './MapLayerGroupToggle'
import MapLayerGroupDropdown from './MapLayerGroupDropdown'
import useMapStore from '../../../state/Map/MapStore'
import { forecastsLayers, freeForecastsLayers } from '../Layers/Shared/RasterLayerCommon'
import { useTranslation } from 'react-i18next'
import { infrastructureLayers } from '../Layers/Shared/InfrastructureLayerCommon'
import NonFreeFeature from '../../Shared/NonFreeFeature'
import useAuthStore from '../../../state/Auth/AuthStore'
import FreeFeature from '../../Shared/FreeFeature'

const MapLayerToggleList = () => {
  const { layerProps, setLayerProps, toggleMapLayer } = useMapStore()
  const { t } = useTranslation()

  return (
    <div tw='flex flex-col absolute bottom-[3.5rem] left-[1.5rem] p-[4px] space-y-1'>
      <MapLayerToggle layerType={MapLayerType.Earthquakes} />
      <MapLayerToggle layerType={MapLayerType.TectonicFaults} />
      <NonFreeFeature>
        <MapLayerGroupDropdown groupName={t('Layers.Groups.Forecasts')} layers={forecastsLayers} />
      </NonFreeFeature>
      <FreeFeature>
        <MapLayerGroupDropdown
          groupName={t('Layers.Groups.Forecasts')}
          layers={freeForecastsLayers}
        />
      </FreeFeature>
      <MapLayerToggle layerType={MapLayerType.TectonicWaves} />
      <MapLayerToggle layerType={MapLayerType.MaxHorizontalStress} />
      <MapLayerToggle layerType={MapLayerType.CriticalRegions}>
        <span
          tw='ml-3 cursor-pointer'
          onClick={(event) => {
            const currentShape = layerProps[MapLayerType.CriticalRegions]?.shape
            setLayerProps(MapLayerType.CriticalRegions, {
              shape: currentShape === 'square' ? 'circle' : 'square',
            })
            toggleMapLayer(MapLayerType.CriticalRegions, true)
            event.stopPropagation()
          }}
        >
          {layerProps[MapLayerType.CriticalRegions]?.shape === 'square' ? '◼' : '●'}
        </span>
      </MapLayerToggle>
      <MapLayerToggle layerType={MapLayerType.MacroseismicIntensity} />
      <MapLayerToggle layerType={MapLayerType.Zones} />
      <NonFreeFeature>
        <MapLayerGroupToggle
          groupName={t('Layers.Groups.Infrastructure')}
          layers={infrastructureLayers}
          initiallyChecked={false}
        />
      </NonFreeFeature>
    </div>
  )
}

export default MapLayerToggleList
