import { Switch } from '@chakra-ui/react'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { useEffect, useState } from 'react'
import MapLayerToggle from './MapLayerToggle'
import useMapStore, { LayerActiveState } from '../../../state/Map/MapStore'

interface MapLayerGroupToggleProps {
  readonly groupName: string
  readonly layers: readonly MapLayerType[]
  readonly initiallyChecked?: boolean
}

const MapLayerGroupToggle = ({ groupName, layers, initiallyChecked }: MapLayerGroupToggleProps) => {
  const [isToggleChecked, setIsToggleChecked] = useState(initiallyChecked ?? true)
  const { isLayerActive, toggleMapLayer } = useMapStore()
  const [wasLayerActive, setWasLayerActive] = useState<LayerActiveState>({ ...isLayerActive })

  const toggleChangeHandler = () => {
    setIsToggleChecked(!isToggleChecked)
  }
  useEffect(() => {
    // TODO: reduce complexity
    if (isToggleChecked) {
      // Re-enable layers
      const groupLayerTypes = new Set(Object.values(layers))
      Object.entries(wasLayerActive).forEach(([mapLayerType, active]) => {
        if (groupLayerTypes.has(mapLayerType as MapLayerType)) {
          toggleMapLayer(mapLayerType as MapLayerType, wasLayerActive[mapLayerType as MapLayerType])
        }
      })
    } else {
      // Disable layers
      setWasLayerActive({ ...isLayerActive })
      Object.values(layers).map((layerType) => toggleMapLayer(layerType, false))
    }
  }, [isToggleChecked])

  return (
    <div tw='flex flex-col space-y-0'>
      <div tw='flex items-center bg-quantectumBlack bg-opacity-60 rounded-full px-[15px] py-[8px]'>
        <Switch size='sm' mr={2} isChecked={isToggleChecked} onChange={toggleChangeHandler} />
        <div
          tw='text-white text-[13px] flex-grow text-center font-bold'
          onClick={toggleChangeHandler}
        >
          {groupName}
        </div>
      </div>
      <div
        tw='ml-9 space-y-0'
        style={{
          display: isToggleChecked ? 'block' : 'none',
        }}
      >
        {layers.map((layerType) => (
          <MapLayerToggle key={layerType} layerType={layerType} isGroupToggle={true} />
        ))}
      </div>
    </div>
  )
}

export default MapLayerGroupToggle
