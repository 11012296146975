import { useEffect, useState } from 'react'
import { Layer, LayerProps, LineLayer, Source } from 'react-map-gl/maplibre'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import { fetchMacroseismicIntensity } from '../../../lib/services/macroseismic-intensity.service'
import { splitFeaturesForMacroseismicIntensity, emptyGeoJson, FeatureCollection } from '../utils'

const layerType = MapLayerType.MacroseismicIntensity
const layerType2 = MapLayerType.MacroseismicIntensityGrey
const layerType3 = MapLayerType.MacroseismicIntensityLabels

const layerProps1: LineLayer = {
  id: layerType,
  source: layerType,
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': ['interpolate', ['linear'], ['zoom'], 0, 1, 10, 7],
  },
}
const layerProps2: LineLayer = {
  id: layerType2,
  source: layerType2,
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': ['interpolate', ['linear'], ['zoom'], 0, 1, 10, 4],
    'line-dasharray': [2, 1],
  },
}
const layerProps3: LayerProps = {
  id: layerType3,
  source: layerType3,
  type: 'symbol',
  layout: {
    'text-field': ['get', 'roman'],
    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-offset': [0, 0],
    'text-anchor': 'center',
  },
  paint: {
    'text-color': '#000',
    'text-halo-color': ['get', 'color'],
    'text-halo-width': 2.5,
    'text-halo-blur': 0.5,
  },
}

const MacroseismicIntensityLayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [contours, setContours] = useState<
    [FeatureCollection, FeatureCollection, FeatureCollection] | null
  >(null)
  const { selected } = useEarthquakesStore()

  useEffect(() => {
    const unsetData = () => {
      setContours(null)
      setIsLoading(false)
    }
    const getMacroseismicIntensity = async () => {
      if (typeof selected === 'undefined') {
        unsetData()
        return
      }
      try {
        const response = await fetchMacroseismicIntensity(selected)
        setContours(splitFeaturesForMacroseismicIntensity(response.data))
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        unsetData()
      }
    }
    getMacroseismicIntensity()
    return () => {
      unsetData()
    }
  }, [selected])

  if (isLoading) {
    return null
  }

  return (
    <>
      <Source type='geojson' data={contours?.[0] || emptyGeoJson}>
        <Layer {...layerProps1} />
      </Source>
      <Source type='geojson' data={contours?.[1] || emptyGeoJson}>
        <Layer {...layerProps2} />
      </Source>
      <Source type='geojson' data={contours?.[2] || emptyGeoJson}>
        <Layer {...layerProps3} />
      </Source>
    </>
  )
}

export default MacroseismicIntensityLayer
