import { Searchable } from '../interfaces/Searchable'

export const search = <T extends Searchable>(searchables: T[], searchTerm: string): T[] => {
  const searchTermLowerCase = searchTerm.toLocaleLowerCase()
  return searchables.filter((searchable: T) => {
    return (
      (searchable.description?.toLocaleLowerCase().indexOf(searchTermLowerCase) ?? -1) !== -1 ||
      (searchable.name?.toLocaleLowerCase().indexOf(searchTermLowerCase) ?? -1) !== -1
    )
  })
}
