import { Searchable } from './Searchable'
import { Sortable } from './Sortable'
import moment from 'moment'

export interface Earthquake extends Sortable, Searchable {
  id?: string
  datetime: string
  latitude: number
  longitude: number
  depth: number
  magnitude: number
  magnitude_type: string
  description: string
  provider: string
  timezone?: string
  datetime_local?: string
  extra?: { [key: string]: any }
  distance_km?: number
  color?: string
}

export const earthquakeId = (earthquake: Earthquake): string => {
  // TODO: this might not be a good-enough id
  return moment.utc(earthquake.datetime, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD_HHmmss')
}
