import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { SearchInputType } from '../../../lib/types/SearchInputType'
import useReportsStore from '../../../state/Reports/ReportsStore'
import SearchInput from '../shared/SearchInput'
import ReportsList from './ReportsList'
import ReportsSortSelect from './ReportsSortSelect'

const ReportsSidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useReportsStore()
  const { t } = useTranslationFunction()

  // Workaround: this is deprecated, keeping it around for a bit longer for analysis convenience
  const openThisSidebar = false

  return (
    <Drawer isOpen={openThisSidebar} placement='right' onClose={toggleSidebar} size='sm'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <div tw='mt-4 text-xl'>{t('Reports.Sidebar.Title')}</div>
        </DrawerHeader>
        <DrawerBody>
          <div tw='mt-4'>
            <SearchInput usedBy={SearchInputType.Reports} />
          </div>
          <div tw='mt-4'>
            <ReportsSortSelect />
          </div>
          <div tw='mt-4'>
            <ReportsList />
          </div>
        </DrawerBody>
        <DrawerFooter></DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default ReportsSidebar
