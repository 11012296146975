import { Button, useDisclosure } from '@chakra-ui/react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { AlertNotification } from '../../../lib/interfaces/AlertNotification'
import { AlertNotificationType } from '../../../lib/types/AlertNotificationType'
import { QuantectumButtonStyles } from '../../../styles/shared/QuantectumButtonStyles'
import AlertNotificationItemDetailsModal from './AlertNotificationItemDetailsModal'
import AlertIcon from './Icons/AlertIcon'
import NotificationIcon from './Icons/NotificationIcon'

const AlertNotificationItem = (props: AlertNotification) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslationFunction()

  return (
    <div tw='flex flex-col overflow-hidden bg-quantectumWhiteGray px-8 py-6 mb-6'>
      <div tw='flex justify-start items-center mb-2'>
        <div tw='text-quantectumBlue'>
          {props.type === AlertNotificationType.Notification ? <NotificationIcon /> : <AlertIcon />}
        </div>
        <div tw='ml-4 text-lg font-semibold'>{props.name}</div>
      </div>
      <div tw='mb-2 truncate'>{props.description} ...</div>
      <div tw='my-4'>
        <span tw='mr-2 font-medium'>{t('AlertNotification.Sidebar.DateText')}</span>
        <span>
          {new Date(props.dateFrom).toLocaleDateString()} -
          {new Date(props.dateTo).toLocaleDateString()}
        </span>
      </div>
      <div>
        <Button {...QuantectumButtonStyles} onClick={onOpen} w='full' py={4}>
          {t('AlertNotification.Sidebar.ViewDetailsButtonText')}
        </Button>
      </div>
      <AlertNotificationItemDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        alertNotification={props}
      />
    </div>
  )
}

export default AlertNotificationItem
