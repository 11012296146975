import { useEffect, useState } from 'react'
import { Layer, Source, FillLayer } from 'react-map-gl/maplibre'
import useDateStore from '../../../state/Date/DateStore'
import { fetchCriticalRegions } from '../../../lib/services/critical-regions.service'
import { convertPointsToRectangles } from '../utils'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import GeoJSON from 'geojson'

const layerType = MapLayerType.CriticalRegions

const layerProps: FillLayer = {
  id: layerType,
  source: layerType,
  type: 'fill',
  paint: {
    'fill-color': '#111',
    'fill-opacity': 0.85,
  },
}

const CriticalRegionsLayerSquares = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [criticalRegions, setCriticalRegions] = useState<GeoJSON.FeatureCollection | null>(null)
  const { date } = useDateStore()

  useEffect(() => {
    const getCriticalRegions = async () => {
      const response = await fetchCriticalRegions(date)
      setCriticalRegions(convertPointsToRectangles(response.data))
      setIsLoading(false)
    }
    getCriticalRegions()
  }, [date])

  if (isLoading) {
    return null
  }

  return (
    <Source type='geojson' data={criticalRegions}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default CriticalRegionsLayerSquares
