import { Layer, Source } from 'react-map-gl/maplibre'
import { loadMapImages, nuclearImages, reprocessNuclearGeoJSON } from '../utils'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { fetchNuclearPowerPlants } from '../../../lib/services/nuclear-power-plant.service'
import { useEffect, useState } from 'react'
import GeoJSON from 'geojson'
import useMapStore from '../../../state/Map/MapStore'
import { MapLayerMouseEvent } from 'maplibre-gl'
import {
  infrastructureLayerProps,
  infrastructureSourceProps,
} from './Shared/InfrastructureLayerCommon'
import useAuthStore from '../../../state/Auth/AuthStore'

const layerType = MapLayerType.NuclearPowerPlants

const NuclearPowerPlantsLayer = () => {
  const [nuclearPowerPlants, setNuclearPowerPlants] = useState<GeoJSON.FeatureCollection | null>(
    null,
  )
  const { map, setPopupInfo } = useMapStore()
  const { isFreeUser } = useAuthStore()

  if (isFreeUser()) {
    return null
  }

  const attribution = 'Nuclear Power Plants © IAEA'

  useEffect(() => {
    if (!map) return

    const fetchAll = async () => {
      const response = await fetchNuclearPowerPlants()
      await loadMapImages(map, nuclearImages)
      setNuclearPowerPlants(response.data)
    }
    setTimeout(fetchAll, 1)

    const onClickPopup = (event: MapLayerMouseEvent) => {
      if (!event.features) return

      const properties = event.features[0].properties
      const content = (
        <div tw='p-4'>
          <div tw='text-lg text-center mb-4'>
            <b>{properties.name}</b>
          </div>
          <div>
            Total net capacity: {properties.totalNetCapacityMW} MW
            <br />
            No. of reactors: {JSON.parse(properties.reactors).length}
          </div>
        </div>
      )
      setPopupInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        content,
      })
    }
    map.on('click', layerType, onClickPopup)
    return () => {
      map.off('click', layerType, onClickPopup)
    }
  }, [map])

  // useNamedEventHandler(layerProps.id as string, (feature) => {
  // nop
  // })

  return (
    <>
      <Source
        {...infrastructureSourceProps()}
        attribution={attribution}
        data={reprocessNuclearGeoJSON(nuclearPowerPlants)}
      >
        <Layer {...infrastructureLayerProps(layerType, 3)} />
      </Source>
    </>
  )
}

export default NuclearPowerPlantsLayer
