import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { Region } from '../../../lib/interfaces/Region'
import { Report } from '../../../lib/interfaces/Report'
import { QuantectumButtonStyles } from '../../../styles/shared/QuantectumButtonStyles'
import MarkdownRender from '../../Shared/MarkdownRender'

interface ReportItemDetailsModalProps {
  isOpen: boolean
  onClose: any
  report: Report
}

const ReportItemDetailsModal = ({ isOpen, onClose, report }: ReportItemDetailsModalProps) => {
  const { t } = useTranslationFunction()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset='slideInBottom'
      scrollBehavior='inside'
      size='3xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div tw='flex items-center'>
            <div>{report.magnitude}</div>
            <span style={{ marginLeft: report.magnitude ? '16px' : '0px' }}>{report.name}</span>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MarkdownRender content={report.description} />
          <div tw='my-6'>
            <span tw='mr-2 font-bold'>{t('Reports.Sidebar.DateText')}</span>
            <span>
              {new Date(report.dateFrom).toLocaleDateString()} -{' '}
              {new Date(report.dateTo).toLocaleDateString()}
            </span>
          </div>
          <div tw='my-6'>
            <span tw='mr-2 font-bold'>{t('Reports.Sidebar.AffectedRegionsText')}</span>
            <ul tw='list-disc ml-4'>
              {report.regions.map((region: Region) => (
                <li key={region.value.latlng}>{region.label}</li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button {...QuantectumButtonStyles} mr={3} onClick={onClose}>
            {t('Reports.Sidebar.CloseButtonText')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReportItemDetailsModal
