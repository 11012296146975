import { fisApiInstance } from '../http'
import moment from 'moment'
import { AlertNotification } from '../interfaces/AlertNotification'

export const fetchAlertsNotifications = async (firstDate: Date, lastDate: Date) => {
  const firstDateFormatted = moment(firstDate).format(moment.HTML5_FMT.DATE)
  const lastDateFormatted = moment(lastDate).format(moment.HTML5_FMT.DATE)

  return await fisApiInstance.get<AlertNotification[]>(
    `/notifications-and-alerts/all-for-customer?dateFrom=${firstDateFormatted}&dateTo=${lastDateFormatted}`,
  )
}
