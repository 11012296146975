export const handleStateBeforeHttpRequest = (set: any) => {
  set({ loading: true, hasErrors: false })
}

export const handleStateAfterSuccessfulHttpRequest = (set: any) => {
  set({ loading: false })
}

export const handleStateAfterUnsuccessfulHttpRequest = (set: any) => {
  set({ hasErrors: true, loading: false })
}
