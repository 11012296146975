export const QuantectumButtonStyles = {
  textColor: 'white',
  bg: 'quantectum.blueLight',
  _hover: {
    bg: 'quantectum.blueDark',
  },
  _active: {
    bg: 'quantectum.blueLight',
  },
  _disabled: {
    opacity: '0.4',
    cursor: 'not-allowed',
    _hover: {
      bg: 'quantectum.blueLight',
    },
  },
}
