import { Switch } from '@chakra-ui/react'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { useEffect, useRef, useState } from 'react'
import useMapStore from '../../../state/Map/MapStore'
import { useTranslation } from 'react-i18next'

interface MapLayerGroupToggleProps {
  readonly groupName: string
  readonly layers: readonly MapLayerType[]
  readonly initiallyChecked?: boolean
}

// Note: you need to set the layer compatibility rules separately so they disable each other
const MapLayerGroupDropdown = ({
  groupName,
  layers,
  initiallyChecked,
}: MapLayerGroupToggleProps) => {
  const [isToggleChecked, setIsToggleChecked] = useState(initiallyChecked ?? true)
  const { toggleMapLayer } = useMapStore()
  const dropdownRef = useRef<HTMLSelectElement>(null)
  const { t } = useTranslation()

  const toggleChangeHandler = () => {
    setIsToggleChecked(!isToggleChecked)
  }

  useEffect(() => {
    toggleMapLayer(dropdownRef.current?.value as MapLayerType, isToggleChecked)
  }, [isToggleChecked])

  return (
    <div tw='flex flex-col space-y-0'>
      <div tw='flex items-center bg-quantectumBlack bg-opacity-60 rounded-full px-[15px] py-[8px]'>
        <Switch size='sm' mr={2} isChecked={isToggleChecked} onChange={toggleChangeHandler} />
        <div
          tw='text-white text-[13px] mx-1 flex-grow text-center font-bold'
          onClick={toggleChangeHandler}
        >
          {groupName}
        </div>
      </div>
      <div
        tw='flex items-center bg-quantectumBlack bg-opacity-60 rounded-full px-[15px] py-[8px] ml-9'
        style={{
          display: isToggleChecked ? 'block' : 'none',
        }}
      >
        <select
          ref={dropdownRef}
          onChange={(event) => {
            toggleMapLayer(event.target.value as MapLayerType, true)
            setIsToggleChecked(true)
          }}
          tw='font-bold text-center'
        >
          {layers.map((layerType) => (
            <option key={layerType} value={layerType}>
              {t(`Layers.${layerType}`)}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default MapLayerGroupDropdown
