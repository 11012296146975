import { Sortable } from '../interfaces/Sortable'
import { SortType } from '../types/SortType'
import { AlertNotificationType } from '../types/AlertNotificationType'
import moment from 'moment'

export const sortByType = <T extends Sortable>(sortables: T[], type: SortType): T[] => {
  switch (type) {
    case SortType.DateAsc:
      return sortByDateAsc(sortables)
    case SortType.DateDsc:
      return sortByDateDsc(sortables)
    case SortType.DateRangeAsc:
      return sortByDateRangeAsc(sortables)
    case SortType.DateRangeDsc:
      return sortByDateRangeDsc(sortables)
    case SortType.MagnitudeAsc:
      return sortByMagnitudeAsc(sortables)
    case SortType.MagnitudeDsc:
      return sortByMagnitudeDsc(sortables)
    case SortType.AlertsFirst:
      return sortByAlertNotificationType(sortables, true)
    case SortType.NotificationsFirst:
      return sortByAlertNotificationType(sortables, false)
    default:
      return sortables
  }
}

const sortByMagnitudeAsc = <T extends Sortable>(sortables: T[]): T[] => {
  const sortablesToSort = [...sortables].filter((s) => s.magnitude)
  const noMagnitude = [...sortables].filter((s) => !s.magnitude)

  return sortablesToSort
    .sort((a: Sortable, b: Sortable) => {
      if (a.magnitude && b.magnitude) {
        return a.magnitude - b.magnitude
      }
      return 0
    })
    .concat([...noMagnitude])
}

const sortByMagnitudeDsc = <T extends Sortable>(sortables: T[]): T[] => {
  const sortablesToSort = [...sortables].filter((s) => s.magnitude)
  const noMagnitude = [...sortables].filter((s) => !s.magnitude)

  return sortablesToSort
    .sort((a: Sortable, b: Sortable) => {
      if (a.magnitude && b.magnitude) {
        return b.magnitude - a.magnitude
      }
      return 0
    })
    .concat([...noMagnitude])
}

const sortByDateAsc = <T extends Sortable>(sortables: T[]): T[] => {
  const sortablesToSort = [...sortables]
  return sortablesToSort.sort((a: T, b: T) => {
    if (a && b) {
      const date1 = moment.utc(a.datetime, 'YYYY-MM-DD HH:mm:ss').toDate()
      const date2 = moment.utc(b.datetime, 'YYYY-MM-DD HH:mm:ss').toDate()
      return date1.getTime() - date2.getTime()
    }
    return 0
  })
}

const sortByDateDsc = <T extends Sortable>(sortables: T[]): T[] => {
  const sortablesToSort = [...sortables]
  return sortablesToSort.sort((a: Sortable, b: Sortable) => {
    if (a && b) {
      const date1 = moment.utc(a.datetime, 'YYYY-MM-DD HH:mm:ss').toDate()
      const date2 = moment.utc(b.datetime, 'YYYY-MM-DD HH:mm:ss').toDate()
      return date2.getTime() - date1.getTime()
    }
    return 0
  })
}

const sortByDateRangeAsc = <T extends Sortable>(sortables: T[]): T[] => {
  const sortablesToSort = [...sortables]
  return sortablesToSort.sort((a: Sortable, b: Sortable) => {
    if (a.dateFrom && b.dateFrom) {
      const date1 = new Date(a.dateFrom)
      const date2 = new Date(b.dateFrom)
      return date1.getTime() - date2.getTime()
    }
    return 0
  })
}

const sortByDateRangeDsc = <T extends Sortable>(sortables: T[]): T[] => {
  const sortablesToSort = [...sortables]
  return sortablesToSort.sort((a: T, b: T) => {
    if (a.dateFrom && b.dateFrom) {
      const date1 = new Date(a.dateFrom)
      const date2 = new Date(b.dateFrom)
      return date2.getTime() - date1.getTime()
    }
    return 0
  })
}

const sortByAlertNotificationType = <T extends Sortable>(
  sortables: T[],
  alertsFirst: boolean,
): T[] => {
  const sortablesToSort = [...sortables]
  const map = new Map<AlertNotificationType, number>()

  map.set(AlertNotificationType.Alert, 0)
  map.set(AlertNotificationType.Notification, 1)

  return sortablesToSort.sort((a: T, b: T) => {
    if (a.type && b.type) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (map.get(a.type)! < map.get(b.type)!) {
        return alertsFirst ? -1 : 1
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (map.get(a.type)! > map.get(b.type)!) {
        return alertsFirst ? 1 : -1
      }
    }
    return 0
  })
}
