import { useEffect, useState } from 'react'
import { Layer, Source, CircleLayer } from 'react-map-gl/maplibre'
import useDateStore from '../../../state/Date/DateStore'
import { fetchCriticalRegions } from '../../../lib/services/critical-regions.service'
import { circleRadiusKm } from '../utils'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.CriticalRegions

const layerProps: CircleLayer = {
  id: layerType,
  source: layerType,
  type: 'circle',
  paint: {
    'circle-color': '#111',
    'circle-opacity': 0.85,
    'circle-radius': circleRadiusKm(105),
  },
}

const CriticalRegionsLayerCircles = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [criticalRegions, setCriticalRegions] = useState('')
  const { date } = useDateStore()

  useEffect(() => {
    const getCriticalRegions = async () => {
      const response = await fetchCriticalRegions(date)
      setCriticalRegions(response.data)
      setIsLoading(false)
    }
    getCriticalRegions()
  }, [date])

  if (isLoading) {
    return null
  }

  return (
    <Source type='geojson' data={criticalRegions}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default CriticalRegionsLayerCircles
