import LoadingSpinner from './LoadingSpinner'

interface LoadingFeedbackProps {
  isLoading: boolean
  children: any
}

const LoadingFeedback = ({ isLoading, children }: LoadingFeedbackProps) => {
  return (
    <>
      {isLoading && (
        <div tw='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'>
          <LoadingSpinner />
        </div>
      )}
      {children()}
    </>
  )
}

export default LoadingFeedback
