import useDateStore from '../../../state/Date/DateStore'
import ArrowButton from './ArrowButton'
import { getDateAddedByDays, getDateSubtractedByDays } from './DateHelper'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import {
  Calendar,
  CalendarControls,
  CalendarDate,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarValues,
  CalendarWeek,
} from '@uselessdev/datepicker'
import { useState } from 'react'

const DatePicker = () => {
  const { date, firstDate, lastDate, setDate } = useDateStore()
  const [isOpen, setIsOpen] = useState(false)
  const [position, setPosition] = useState({ left: 0, top: 0 })

  const handleDateBackClick = (days: number) => {
    setDate(getDateSubtractedByDays(date, days))
  }

  const handleDateForwardClick = (days: number) => {
    setDate(getDateAddedByDays(date, days))
  }

  const handleDatepickerSelect = (value: CalendarDate | CalendarValues) => {
    setDate(value as Date)
  }

  return (
    <div tw='flex items-center select-none'>
      <ArrowButton
        onClickHandler={() => handleDateBackClick(7)}
        variant='left'
        title={'Backward by 7 days'}
        double={true}
      />
      <ArrowButton
        onClickHandler={() => handleDateBackClick(1)}
        variant='left'
        title={'Backward by 1 day'}
      />
      <div
        tw='text-white cursor-pointer'
        title={'Open date picker'}
        onClick={(event) => {
          setPosition({ left: event.clientX, top: event.clientY })
          setIsOpen(true)
        }}
      >
        <span tw='mx-2'>{firstDate.toLocaleDateString()}</span>
        <span tw='mx-1'> - </span>
        <span tw='mx-2'>{lastDate.toLocaleDateString()}</span>
      </div>
      <ArrowButton
        onClickHandler={() => handleDateForwardClick(1)}
        variant='right'
        title={'Forward by 1 day'}
      />
      <ArrowButton
        onClickHandler={() => handleDateForwardClick(7)}
        variant='right'
        title={'Forward by 7 days'}
        double={true}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContent
          maxWidth={'360px'}
          style={{
            position: 'fixed',
            left: `calc(${position.left}px - 180px)`,
            top: `calc(${position.top}px - 40px)`,
          }}
        >
          <ModalHeader>Select a Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Calendar
              value={{ start: date, end: date }}
              singleDateSelection={true}
              onSelectDate={handleDatepickerSelect}
            >
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>
              <CalendarMonths>
                <CalendarMonth>
                  <CalendarMonthName />
                  <CalendarWeek />
                  <CalendarDays />
                </CalendarMonth>
              </CalendarMonths>
            </Calendar>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default DatePicker
