export enum SortType {
  DateAsc = 'DateAsc',
  DateDsc = 'DateDsc',
  DateRangeAsc = 'DateRangeAsc',
  DateRangeDsc = 'DateRangeDsc',
  MagnitudeAsc = 'MagnitudeAsc',
  MagnitudeDsc = 'MagnitudeDsc',
  AlertsFirst = 'AlertsFirst',
  NotificationsFirst = 'NotificationsFirst',
}
