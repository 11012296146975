import { Switch } from '@chakra-ui/react'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import useMapStore from '../../../state/Map/MapStore'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface MapLayerToggleProps {
  readonly layerName?: string
  readonly layerType: MapLayerType
  readonly isGroupToggle?: boolean
  readonly children?: ReactNode
}

const MapLayerToggle = ({ layerName, layerType, isGroupToggle, children }: MapLayerToggleProps) => {
  const { isLayerActive, toggleMapLayer } = useMapStore()
  const { t } = useTranslation()
  if (!layerName) layerName = layerType

  return (
    <div
      tw='flex items-center justify-between bg-quantectumBlack bg-opacity-60 rounded-full px-[15px] py-[8px]'
      style={isGroupToggle ? {} : { minWidth: '240px' }}
    >
      <Switch
        size='sm'
        mr={2}
        isChecked={isLayerActive[layerType]}
        onChange={() => toggleMapLayer(layerType)}
      />
      <div
        tw='text-white text-[13px] flex-grow text-center font-bold'
        onClick={() => toggleMapLayer(layerType)}
      >
        {t(`Layers.${layerName}`)}
        {children}
      </div>
    </div>
  )
}

export default MapLayerToggle
