import { Button } from '@chakra-ui/react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import useReportsStore from '../../../state/Reports/ReportsStore'
import MapIcon from '../../../assets/images/map-icon.png'

const ReportsButton = () => {
  const { toggleSidebar } = useReportsStore()
  const { t } = useTranslationFunction()

  const handleButtonClick = () => {
    toggleSidebar()
  }

  return (
    <Button
      onClick={handleButtonClick}
      leftIcon={<img src={MapIcon} />}
      textColor='white'
      bg='quantectum.blue'
      fontWeight='normal'
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
    >
      {t('Reports.HeaderTitle')}
    </Button>
  )
}

export default ReportsButton
