// Note: The order also defines the display order on the map
// Duplication: translations.json
export enum MapLayerType {
  // Below rasters
  // Rasters
  TectonicTraction = 'TectonicTraction',
  SmoothedSeismicity = 'SmoothedSeismicity',
  DynamicSeismicPotential = 'DynamicSeismicPotential',
  AverageSeismicPotential = 'AverageSeismicPotential',
  ShearStress = 'ShearStress',
  ZonesIndonesia = 'ZonesIndonesia',
  GeologyIndonesia = 'GeologyIndonesia',
  // Above rasters
  TectonicFaults = 'TectonicFaults',
  TectonicWaves = 'TectonicWaves',
  MaxHorizontalStress = 'MaxHorizontalStress',
  CriticalRegions = 'CriticalRegions',
  // Infrastructure
  StockExchanges = 'StockExchanges',
  Dams = 'Dams',
  NuclearPowerPlants = 'NuclearPowerPlants',
  CountryStats = 'CountryStats',
  // Shakemaps
  MacroseismicIntensity = 'MacroseismicIntensity',
  MacroseismicIntensityGrey = 'MacroseismicIntensityGrey',
  MacroseismicIntensityLabels = 'MacroseismicIntensityLabels',
  // Earthquakes
  Earthquakes = 'Earthquakes',
  // Above ground
  Nightshade = 'Nightshade',
  Zones = 'Zones',
}
