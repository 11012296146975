import ResetPasswordForm from '../components/Auth/ResetPasswordForm'

const ResetPasswordPage = () => {
  return (
    <div tw='bg-white w-[460px] h-auto'>
      <div tw='p-[50px]'>
        <ResetPasswordForm />
      </div>
    </div>
  )
}

export default ResetPasswordPage
