import { Layer, LayerProps, Source, SourceProps } from 'react-map-gl/maplibre'
import { rasterLayerProps, rasterSourceProps } from './Shared/RasterLayerCommon'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import useAuthStore from '../../../state/Auth/AuthStore'

const layerType = MapLayerType.GeologyIndonesia

const bounds = [
  [91.9917696, -12.6677242], // Southwest coordinates
  [141.0636245, 12.5377229], // Northeast coordinates
]

const sourceProps: SourceProps = {
  id: layerType,
  type: 'image',
  url: 'temp/GeologyIndonesiaHiRes.png',
  coordinates: [
    [bounds[0][0], bounds[1][1]], // Upper Left
    [bounds[1][0], bounds[1][1]], // Upper Right
    [bounds[1][0], bounds[0][1]], // Lower Right
    [bounds[0][0], bounds[0][1]], // Lower Left
  ],
}

const layerProps: LayerProps = {
  id: layerType,
  type: 'raster',
  source: layerType,
  paint: {
    'raster-opacity': 0.75,
  },
}

const ZonesIndonesiaLayer = () => {
  const { isFreeUser } = useAuthStore()

  if (isFreeUser()) {
    return null
  }

  return (
    <Source {...sourceProps}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default ZonesIndonesiaLayer
