import { Layer, LayerProps, Source } from 'react-map-gl/maplibre'
import {
  circleRadiusKm,
  stockExchangesCsvToGeoJson,
  loadMapImages,
  reprocessStockExchangesGeoJSON,
  stockExchangeImages,
} from '../utils'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { useEffect, useState } from 'react'
import GeoJSON from 'geojson'
import useMapStore from '../../../state/Map/MapStore'
import { fetchStockExchanges } from '../../../lib/services/stock-exchange.service'
import { MapLayerMouseEvent } from 'maplibre-gl'
import {
  infrastructureLayerProps,
  infrastructureSourceProps,
} from './Shared/InfrastructureLayerCommon'
import useAuthStore from '../../../state/Auth/AuthStore'

const layerType = MapLayerType.StockExchanges

const StockExchangesLayer = () => {
  const [stockExchanges, setStockExchanges] = useState<GeoJSON.FeatureCollection | null>(null)
  const { map, setPopupInfo } = useMapStore()
  const { isFreeUser } = useAuthStore()

  if (isFreeUser()) {
    return null
  }

  // Visual Capitalist merged the data (2016), Vecteezy is for the icon
  const attribution =
    'Stock exchanges © World Federation of Exchanges, LSE, Visual Capitalist, Vecteezy'

  useEffect(() => {
    if (!map) return

    const fetchAll = async () => {
      await loadMapImages(map, stockExchangeImages)
      setStockExchanges(stockExchangesCsvToGeoJson(await fetchStockExchanges()))
    }
    setTimeout(fetchAll, 1)

    const onClickPopup = (event: MapLayerMouseEvent) => {
      if (!event.features) return

      const properties = event.features[0].properties
      console.log(properties)
      // TODO: why doesn't it take default fonts? (Helvetica instead of Apercu)
      const content = (
        <div tw='p-4'>
          <div tw='text-lg text-center mb-4'>
            <b>{properties.name}</b>
          </div>
          <div>Country: {properties.country}</div>
          <div>Value: ${properties.value}B</div>
        </div>
      )
      setPopupInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        content,
      })
    }
    map.on('click', layerType, onClickPopup)
    return () => {
      map.off('click', layerType, onClickPopup)
    }
  }, [map])

  return (
    <Source
      {...infrastructureSourceProps()}
      attribution={attribution}
      data={reprocessStockExchangesGeoJSON(stockExchanges)}
    >
      <Layer {...infrastructureLayerProps(layerType, 3)} />
    </Source>
  )
}

export default StockExchangesLayer
