import { Spinner, useTheme } from '@chakra-ui/react'
import useReportsStore from '../../../state/Reports/ReportsStore'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import { fetchReportToDataUrl } from '../../../lib/services/report.service'
import { useEffect, useState } from 'react'
import useAuthStore from '../../../state/Auth/AuthStore'

const ReportsOverlay = () => {
  const { isSidebarOpen } = useReportsStore()
  const { isFreeUser } = useAuthStore()
  const {
    selected: earthquake,
    isSidebarOpen: isEarthquakeSidebarOpen,
    toggleSidebar: toggleEarthquakeSidebar,
  } = useEarthquakesStore()
  const [pdfDataUrl, setPdfDataUrl] = useState<string | null>('')
  const theme = useTheme()
  const sidebarSize = theme.sizes.lg // Duplication: EarthquakesSidebar
  const headerSize = '70px' // Duplication: HeaderMenu

  useEffect(() => {
    let isCancelled = false
    setPdfDataUrl('')
    if (!earthquake) return
    const fetchData = async () => {
      if (!isCancelled) {
        try {
          const dataUrl = await fetchReportToDataUrl(
            earthquake,
            // isFreeUser() ? 'fis-report-free' : 'fis-report',
            'fis-report',
          )
          if (!isCancelled) {
            setPdfDataUrl(dataUrl)
          }
        } catch (error) {
          console.warn(error)
          setPdfDataUrl(null)
        }
      }
    }
    fetchData()
    return () => {
      isCancelled = true
    }
  }, [earthquake])

  if (isSidebarOpen && !isEarthquakeSidebarOpen) {
    toggleEarthquakeSidebar(true)
  }

  return (
    <>
      {isSidebarOpen && earthquake && (
        <>
          <div
            style={{
              top: headerSize,
              right: sidebarSize,
              width: `calc(100% - ${sidebarSize})`,
              zIndex: 1500,
              visibility: pdfDataUrl === '' ? 'hidden' : 'visible',
            }}
            tw='fixed flex bottom-0 left-0 z-10'
          >
            <div tw='m-auto flex items-center justify-center w-full h-full pt-0 px-0 text-white font-bold text-lg'>
              {pdfDataUrl === null ? (
                <>Event report not found.</>
              ) : (
                <embed
                  src={pdfDataUrl}
                  type='application/pdf'
                  style={{ width: '100%', height: '100%', zIndex: 1 }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              top: headerSize,
              right: sidebarSize,
              width: '100%',
              zIndex: 0, // Note: The spinner is always at the bottom
            }}
            tw='fixed flex bottom-0 left-0 z-10 bg-black bg-opacity-50'
          >
            <div tw='absolute flex justify-center items-center w-full h-full text-white'>
              {pdfDataUrl === null ? <></> : <Spinner size={'xl'} />}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ReportsOverlay
