import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useResetPasswordState } from '../../hooks/useResetPasswordState'
import { ResetPasswordFormValues } from '../../lib/interfaces/ResetPasswordFormValues'
import { QuantectumButtonStyles } from '../../styles/shared/QuantectumButtonStyles'
import LoadingFeedback from '../Shared/LoadingFeedback'

const ResetPasswordForm = () => {
  const {
    showPassword,
    showMatchingPassword,
    isLoading,
    handleShowPasswordClick,
    handleShowMatchingPasswordClick,
    handleOnSubmit,
    t,
  } = useResetPasswordState()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<ResetPasswordFormValues>({
    defaultValues: {
      email: '',
      password: '',
      matchingPassword: '',
    },
    mode: 'onChange',
  })

  const watchPassword = watch('password')
  const watchMatchingPassword = watch('matchingPassword')

  const arePasswordsMatching = (): boolean => {
    return watchPassword === watchMatchingPassword
  }

  const onSubmit: SubmitHandler<ResetPasswordFormValues> = async (
    formData: ResetPasswordFormValues,
  ) => {
    handleOnSubmit(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} tw='relative'>
      <LoadingFeedback isLoading={isLoading}>
        {() => {
          return (
            <>
              <div tw='text-center text-[18px] font-bold mb-[24px]'>
                {t('Auth.ResetPasswordForm.Title')}
              </div>
              <FormControl id='email' isInvalid={errors.email !== undefined}>
                <FormLabel fontWeight='normal'>
                  {t('Auth.ResetPasswordForm.EmailFormLabel')}
                </FormLabel>
                <Input type='email' {...register('email', { required: true })} />
                <FormErrorMessage>
                  {t('Auth.ResetPasswordForm.EmailRequiredMessage')}
                </FormErrorMessage>
              </FormControl>
              <FormControl id='password' mt={4} isInvalid={errors.password !== undefined}>
                <FormLabel fontWeight='normal'>
                  {t('Auth.ResetPasswordForm.PasswordFormLabel')}
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', { required: true })}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.5rem' size='sm' onClick={handleShowPasswordClick}>
                      {showPassword
                        ? t('Auth.Shared.HidePasswordText')
                        : t('Auth.Shared.ShowPasswordText')}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {t('Auth.ResetPasswordForm.PasswordRequiredMessage')}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id='matchingPassword'
                mt={4}
                isInvalid={errors.matchingPassword !== undefined}
              >
                <FormLabel fontWeight='normal'>
                  {t('Auth.ResetPasswordForm.ConfirmPasswordFormLabel')}
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    type={showMatchingPassword ? 'text' : 'password'}
                    {...register('matchingPassword', { required: true })}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.5rem' size='sm' onClick={handleShowMatchingPasswordClick}>
                      {showMatchingPassword
                        ? t('Auth.Shared.HidePasswordText')
                        : t('Auth.Shared.ShowPasswordText')}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {t('Auth.ResetPasswordForm.ConfirmPasswordRequiredMessage')}
                </FormErrorMessage>
              </FormControl>
              <div tw='mt-8'>
                <Button
                  {...QuantectumButtonStyles}
                  disabled={!isValid || !arePasswordsMatching()}
                  type='submit'
                  w='full'
                  py={7}
                >
                  {t('Auth.ResetPasswordForm.ResetYourPasswordButtonText')}
                </Button>
              </div>
            </>
          )
        }}
      </LoadingFeedback>
    </form>
  )
}

export default ResetPasswordForm
