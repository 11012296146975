import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useCurrentPath from '../../hooks/useCurrentPath'
import AlarmsNotificationsButton from './AlertsNotifications/AlertsNotificationsButton'
import DarkBlueHeaderWrapper from './DarkBlueHeaderWrapper'
import DatePicker from './DatePicker/DatePicker'
import HeaderMenu from './HeaderMenu'
import LogoSmall from './LogoSmall'
import ReportsButton from './Reports/ReportsButton'
import NonFreeFeature from '../Shared/NonFreeFeature'

const AppBar = () => {
  const [showControls, setShowControls] = useState(true)

  const currentPath = useCurrentPath()

  useEffect(() => {
    setShowControls(currentPath !== '/profile')
  }, [currentPath])

  return (
    <header tw='flex items-center justify-between bg-quantectumBlue h-[70px] w-full'>
      <DarkBlueHeaderWrapper>
        <Link to='/'>
          <LogoSmall />
        </Link>
      </DarkBlueHeaderWrapper>
      {showControls && (
        <div>
          <ReportsButton />
        </div>
      )}
      {showControls && (
        <div>
          <NonFreeFeature>
            <AlarmsNotificationsButton />
          </NonFreeFeature>
        </div>
      )}
      {showControls && (
        <div>
          <DatePicker />
        </div>
      )}
      <div tw='h-full'>
        <DarkBlueHeaderWrapper>
          <HeaderMenu />
        </DarkBlueHeaderWrapper>
      </div>
    </header>
  )
}

export default AppBar
