import { Button } from '@chakra-ui/react'
import ProfileForm from '../components/Profile/ProfileForm'
import SubscribedRegionsList from '../components/Profile/SubscribedRegionsList'
import LoadingFeedback from '../components/Shared/LoadingFeedback'
import { useForgotPasswordState } from '../hooks/useForgotPasswordState'
import { useTranslationFunction } from '../hooks/useTranslationFunction'
import useAuthStore from '../state/Auth/AuthStore'
import { QuantectumButtonStyles } from '../styles/shared/QuantectumButtonStyles'

const ProfilePage = () => {
  const { currentUser } = useAuthStore()
  const { isLoading, handleOnSubmit: forgotPasswordRequest } = useForgotPasswordState()
  const { t } = useTranslationFunction()

  const handleResetPasswordClick = () => {
    if (currentUser?.email) {
      forgotPasswordRequest({ email: currentUser?.email })
    }
  }

  return (
    <div tw='flex flex-col w-full h-full p-4 max-w-6xl mx-auto relative'>
      <LoadingFeedback isLoading={isLoading}>
        {() => {
          return (
            <>
              <h2 tw='text-lg font-medium mt-4'>{t('Profile.Page.Title')}</h2>
              <div tw='mt-8'>
                <ProfileForm currentUser={currentUser} />
              </div>
              <div tw='mt-12 md:w-1/2 pr-4'>
                <Button
                  onClick={handleResetPasswordClick}
                  {...QuantectumButtonStyles}
                  type='submit'
                  w='full'
                  py={4}
                >
                  {t('Profile.Page.ResetPasswordButtonText')}
                </Button>
              </div>
              <div tw='mt-12 md:w-1/2 pr-4'>
                <SubscribedRegionsList regions={currentUser?.regions} />
              </div>
            </>
          )
        }}
      </LoadingFeedback>
    </div>
  )
}

export default ProfilePage
