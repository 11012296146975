import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ConfirmAccountFormValues } from '../lib/interfaces/ConfirmAccountFormValues'
import useAuthStore from '../state/Auth/AuthStore'
import { useTranslationFunction } from './useTranslationFunction'

export const useConfirmAccountState = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showMatchingPassword, setShowMatchingPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { confirmAccount } = useAuthStore()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { t } = useTranslationFunction()

  const handleShowPasswordClick = () => {
    setShowPassword((prevState) => !prevState)
  }

  const handleShowMatchingPasswordClick = () => {
    setShowMatchingPassword((prevState) => !prevState)
  }

  const handleOnSubmit = async (formData: ConfirmAccountFormValues) => {
    setIsLoading(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const success = await confirmAccount(
      { email: formData.email, password: formData.password },
      searchParams.get('userId')!,
    )
    setIsLoading(false)

    if (success) {
      toast({
        title: t('Auth.ConfirmAccountForm.Toast.Success.Title'),
        description: t('Auth.ConfirmAccountForm.Toast.Success.Description'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      navigate('/')
    } else {
      toast({
        title: t('Auth.ConfirmAccountForm.Toast.Error.Title'),
        description: t('Auth.ConfirmAccountForm.Toast.Error.Description'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return {
    showPassword,
    showMatchingPassword,
    isLoading,
    handleShowPasswordClick,
    handleShowMatchingPasswordClick,
    handleOnSubmit,
    t,
  }
}
