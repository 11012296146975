import { range } from 'd3-array'
import { scaleQuantile } from 'd3-scale'
import type GeoJSON from 'geojson'
import { Earthquake } from '../../lib/interfaces/Earthquake'
import { Map, ExpressionSpecification, RequestParameters, ResourceType } from 'maplibre-gl'
import { isMapboxURL, transformMapboxUrl } from 'maplibregl-mapbox-request-transformer'
import Papa from 'papaparse'

export type FeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Geometry,
  GeoJSON.GeoJsonProperties
>

export const emptyGeoJson = {
  type: 'FeatureCollection',
  features: [],
}

export function updatePercentiles(
  featureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
  accessor: (f: GeoJSON.Feature<GeoJSON.Geometry>) => number,
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  const { features } = featureCollection
  const scale = scaleQuantile().domain(features.map(accessor)).range(range(9))
  return {
    type: 'FeatureCollection',
    features: features.map((f) => {
      const value = accessor(f)
      const properties = {
        ...f.properties,
        value,
        percentile: scale(value),
      }
      return { ...f, properties }
    }),
  }
}

export function earthquakesToGeoJSON(earthquakes: Earthquake[]): FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: earthquakes.map((earthquake) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [earthquake.longitude, earthquake.latitude],
      },
      properties: {
        id: earthquake.id,
        radius: Math.max(1.5, (earthquake.magnitude - 3) * 4),
        color: earthquake.color,
      },
    })),
  }
}

const createRectangleFromPoint = (
  point: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>,
  xOffset: number,
  yOffset: number,
): GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties> => {
  const [lon, lat] = point.geometry.coordinates
  return {
    type: 'Feature',
    properties: point.properties,
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [lon - xOffset, lat - yOffset],
          [lon + xOffset, lat - yOffset],
          [lon + xOffset, lat + yOffset],
          [lon - xOffset, lat + yOffset],
          [lon - xOffset, lat - yOffset],
        ],
      ],
    },
  }
}

export const convertPointsToRectangles = (
  featureCollection: GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>,
  xOffset = 0.5,
  yOffset = 0.5,
): GeoJSON.FeatureCollection<GeoJSON.Polygon, GeoJSON.GeoJsonProperties> => {
  return {
    type: 'FeatureCollection',
    features: featureCollection.features.map((feature) =>
      createRectangleFromPoint(feature, xOffset, yOffset),
    ),
  }
}

export function splitFeaturesForMacroseismicIntensity(
  geojson: FeatureCollection,
): [FeatureCollection, FeatureCollection, FeatureCollection] {
  const featuresWithColor = geojson.features.filter(
    (feature) => feature.properties?.color !== '#666',
  )

  const featuresWithoutColor = geojson.features.filter(
    (feature) => feature.properties?.color === '#666',
  )

  const geoJsonWithColor: FeatureCollection = {
    type: 'FeatureCollection',
    features: featuresWithColor,
  }

  const geoJsonWithoutColor: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
  > = {
    type: 'FeatureCollection',
    features: featuresWithoutColor,
  }

  const southMostPoints: {
    [intensity: number]: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>
  } = {}

  featuresWithColor.forEach((feature) => {
    if (feature.geometry.type === 'LineString') {
      const coordinates = feature.geometry.coordinates
      let minLat = Infinity
      let southMostCoord: number[] = [0, 0]

      coordinates.forEach((coord) => {
        if (coord[1] < minLat) {
          minLat = coord[1]
          southMostCoord = coord
        }
      })

      const floatToRoman: { [key: number]: string } = {
        1.5: 'II',
        2.5: 'III',
        3.5: 'IV',
        4.5: 'V',
        5.5: 'VI',
        6.5: 'VII',
        7.5: 'VIII',
        8.5: 'IX',
        9.5: 'X',
      }

      const southMostFeature: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: southMostCoord,
        },
        properties: {
          ...feature.properties,
          roman: floatToRoman[feature.properties?.intensity],
        },
      }

      if (
        !southMostPoints[feature.properties?.intensity] ||
        southMostPoints[feature.properties?.intensity].geometry.coordinates[1] > southMostCoord[1]
      ) {
        southMostPoints[feature.properties?.intensity] = southMostFeature
      }
    }
  })

  const geoJsonSouthMostPoints: FeatureCollection = {
    type: 'FeatureCollection',
    features: Object.values(southMostPoints),
  }

  return [geoJsonWithColor, geoJsonWithoutColor, geoJsonSouthMostPoints]
}

// (Pre)load map icons
// TODO: see map.loadImages
export const loadMapImages = (map: Map, images: { [id: string]: string }) =>
  Promise.all(
    Object.entries(images).map(([id, url]) => {
      return new Promise<void>((resolve, reject) => {
        console.log('Loading', id, url)
        map?.loadImage(url, (error, image) => {
          if (error || !image) {
            reject(error || Error('Image is undefined'))
          } else {
            console.log('Adding', id, url)
            map?.addImage(id, image)
            resolve()
          }
        })
      })
    }),
  )

// Nuclear power plant
type Reactor = {
  name: string
  type: string
  model: string
  net_capacity_MW: number
  operator: string
  commercial_start_date: string
  status: string
}

// Register these infrastructure with map.addImage(...)
export const nuclearImages = {
  nuclear: 'infrastructure/nuclear/nuclear.png',
  nuclearGrey: 'infrastructure/nuclear/nuclear_grey.png',
  nuclearMixed: 'infrastructure/nuclear/nuclear_mixed.png',
}

export function reprocessNuclearGeoJSON(
  nuclearPowerPlants: FeatureCollection | null,
): FeatureCollection {
  const features = !nuclearPowerPlants
    ? []
    : nuclearPowerPlants.features.map((feature) => {
        if (!feature.properties) return feature
        const reactors = feature.properties.reactors as Reactor[]
        const totalCapacity = reactors.reduce((sum, r) => sum + r.net_capacity_MW, 0)

        let iconId = ''
        const allOperational = reactors.every((r) => r.status === 'operational')
        const noneOperational = reactors.every((r) => r.status !== 'operational')

        if (allOperational) {
          iconId = 'nuclear'
        } else if (noneOperational) {
          iconId = 'nuclearGrey'
        } else {
          iconId = 'nuclearMixed'
        }

        // Partly taken from GIS, with some room for customization
        const baseSize = 14
        const sizeRatio = baseSize / 14
        const size = (baseSize + (sizeRatio * totalCapacity) / 400) / 10

        return {
          ...feature,
          properties: {
            ...feature.properties,
            'icon-id': iconId,
            'icon-size': size,
            'icon-anchor': 'center',
            totalNetCapacityMW: totalCapacity,
          },
        }
      })

  return {
    type: 'FeatureCollection',
    features: features,
  }
}

// Dams
export const damsImages = {
  dam: 'infrastructure/dams/dam.png',
}

export function reprocessDamsGeoJSON(dams: FeatureCollection | null): FeatureCollection {
  const features = !dams
    ? []
    : dams.features.map((feature) => {
        if (!feature.properties) return feature

        const baseSize = 14

        return {
          ...feature,
          properties: {
            ...feature.properties,
            'icon-id': 'dam',
            'icon-size': baseSize,
            'icon-anchor': 'center',
          },
        }
      })

  return {
    type: 'FeatureCollection',
    features: features,
  }
}

// Stock Exchanges
export const stockExchangeImages = {
  stockExchange: 'infrastructure/stock-exchange/stock-exchange.png',
}

export function reprocessStockExchangesGeoJSON(
  stockExchanges: FeatureCollection | null,
): FeatureCollection {
  const features = !stockExchanges
    ? []
    : stockExchanges.features
        .filter((row: any) => row['Latitude'] || row['Longitude'])
        .map((feature) => {
          if (!feature.properties) return feature

          // Partly taken from GIS, with some room for customization
          const baseSize = 14
          const sizeRatio = baseSize / 14
          const size = (baseSize + (sizeRatio * feature.properties.value) / 10) / 10

          return {
            ...feature,
            properties: {
              ...feature.properties,
              'icon-id': 'stockExchange',
              'icon-size': size,
              'icon-anchor': 'center',
            },
          }
        })

  return {
    type: 'FeatureCollection',
    features: features,
  }
}

export const stockExchangesCsvToGeoJson = (csvString: string): GeoJSON.FeatureCollection => {
  const parseResult = Papa.parse(csvString, {
    header: true,
    dynamicTyping: true,
  })

  const features = parseResult.data.map((row: any) => {
    console.log(row)
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [row['lon'], row['lat']],
      },
      properties: {
        name: row['Name'],
        country: row['Country'],
        value: row['Value in $ Billions'],
      },
    } as GeoJSON.Feature
  })
  console.log(features)

  return {
    type: 'FeatureCollection',
    features,
  }
}

// Workaround: MapBox url support in MapLibre
export function transformMapboxRequest(
  url: string,
  resourceType?: ResourceType,
): RequestParameters {
  if (isMapboxURL(url)) {
    return transformMapboxUrl(
      url,
      resourceType as ResourceType,
      process.env.REACT_APP_MAPBOX_ACCESSTOKEN as string,
    )
  }
  return { url }
}

// TODO: this might be on GIS later
export function generateZonesGrid() {
  const grid: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  }

  let idCounter = 0
  for (let lat = -90; lat < 90; lat += 10) {
    for (let lon = -180; lon < 180; lon += 10) {
      idCounter += 1
      const square: GeoJSON.Feature = {
        type: 'Feature',
        id: `zone_${idCounter}`,
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [lon, lat],
              [lon + 20, lat],
              [lon + 20, lat + 20],
              [lon, lat + 20],
              [lon, lat],
            ],
          ],
        },
      }
      grid.features.push(square)
    }
  }

  return grid
}

// GIS utils
const earthCircumferenceKm = 40075
const basePixelSize = 256 / earthCircumferenceKm

function pixelSizeForZoom(zoom: number, sizeKm: number): number {
  return sizeKm * (basePixelSize * Math.pow(2, zoom))
}

function pixelSizes(sizeKm: number, minZoom = 0, maxZoom = 22) {
  return [...Array(maxZoom + 1).keys()]
    .map((zoom) => [zoom, pixelSizeForZoom(zoom, sizeKm)])
    .slice(minZoom)
}

export function circleRadiusKm(sizeKm: number, minZoom = 0, maxZoom = 22): ExpressionSpecification {
  return ['interpolate', ['linear'], ['zoom'], ...pixelSizes(sizeKm, minZoom, maxZoom).flat()]
}

export function formatNumberWithUnit(num: number, places?: number): string {
  const units = ['', 'k', 'M', 'B']
  const originalNum = num

  let unitIndex = 0
  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000
    unitIndex++
  }

  console.log(num, typeof num)

  return num.toFixed(places ?? (originalNum >= 1000 ? 1 : 0)) + units[unitIndex]
}
