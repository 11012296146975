import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { defaultsDataState } from '../../lib/config/defaults-data-state'
import { BaseDataState } from '../../lib/interfaces/BaseDataState'
import { Sortable } from '../../lib/interfaces/Sortable'
import { search } from '../../lib/services/search.service'
import { sortByType } from '../../lib/services/sorting.service'
import { SortType } from '../../lib/types/SortType'
import {
  handleStateAfterSuccessfulHttpRequest,
  handleStateAfterUnsuccessfulHttpRequest,
  handleStateBeforeHttpRequest,
} from '../state-helper'
import { fetchEarthquakes } from '../../lib/services/earthquake.service'
import { Earthquake } from '../../lib/interfaces/Earthquake'
import useDateStore from '../Date/DateStore'

interface EarthquakesState extends BaseDataState<Earthquake> {
  dataAll: Earthquake[] // Data as it comes from the API, `data` may be filtered / sorted
  isSidebarOpen: boolean
  sortType: SortType | string
  toggleSidebar: (desiredState?: boolean) => void
  getAll: () => any
  sort: (sortType?: SortType | string) => void
  search: (searchTerm: string) => void
  searchTerm: string
  reset: () => void
  selected?: Earthquake
  select: (selected?: Earthquake | string) => void
  provider: string
  minMagnitude: number
  setMinMagnitude: (minMagnitude: number) => void
  filterData: (earthquakes: Earthquake[]) => Earthquake[]
}

const initialState = {
  ...defaultsDataState,
  dataAll: [],
  isSidebarOpen: false,
  sortType: (localStorage.getItem('earthquakeSortValue') as string) || SortType.MagnitudeDsc,
  searchTerm: '',
  provider: 'USGS',
  minMagnitude: parseFloat(localStorage.getItem('earthquakeMinMagnitude') as string) || 4.5,
}

const useEarthquakesStore = create<EarthquakesState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      toggleSidebar: (desiredState?: boolean) => {
        // Duplication: EarthquakeStore
        const isSidebarOpen = get().isSidebarOpen
        let newIsSidebarOpen = !isSidebarOpen
        if (typeof desiredState === 'boolean') {
          newIsSidebarOpen = desiredState
        }
        if (newIsSidebarOpen !== isSidebarOpen) {
          set(() => ({
            isSidebarOpen: newIsSidebarOpen,
          }))
        }
      },
      getAll: async () => {
        const { firstDate, lastDate } = useDateStore.getState()
        handleStateBeforeHttpRequest(set)
        try {
          const response = await fetchEarthquakes(firstDate, lastDate, get().provider)
          handleStateAfterSuccessfulHttpRequest(set)
          set({ dataAll: response.data })
          set({ data: get().filterData(get().dataAll) })
          // Re-select event
          if (!response.data.find((eq: Earthquake) => eq.id === get().selected?.id)) {
            get().select()
          }
        } catch (error) {
          handleStateAfterUnsuccessfulHttpRequest(set)
        }
      },
      sort: (sortType?: SortType | string) => {
        if (typeof sortType === 'undefined') sortType = get().sortType
        set({ sortType: sortType })
        localStorage.setItem('earthquakeSortValue', sortType)
        set({ data: get().filterData(get().dataAll) })
      },
      search: (searchTerm: string) => {
        set({ searchTerm })
        set({ data: get().filterData(get().dataAll) })
      },
      reset: () => set(initialState),
      select: (selected?: Earthquake | string) => {
        if (typeof selected === 'string') {
          // Select by id
          selected = get().data.find((eq) => eq.id === selected)
        }
        if (selected !== get().selected) {
          set({ selected })
        }
      },
      setMinMagnitude: (minMagnitude) => {
        set({ minMagnitude })
        localStorage.setItem('earthquakeMinMagnitude', minMagnitude.toString())
        set({ data: get().filterData(get().dataAll) })
      },
      filterData: (earthquakes) => {
        earthquakes = earthquakes.filter((eq) => eq.magnitude >= get().minMagnitude)
        if (get().searchTerm !== '') earthquakes = search(earthquakes, get().searchTerm)
        earthquakes = sortByType(earthquakes, get().sortType as SortType)
        return earthquakes
      },
    }),
    {
      name: 'earthquakes-storage',
    },
  ),
)

// When date is updated, get the Earthquakes
useDateStore.subscribe(async () => {
  await useEarthquakesStore.getState().getAll()
  useEarthquakesStore.getState().sort()
})
// Initial Earthquake get and sort - may be a hack
await useEarthquakesStore.getState().getAll()
useEarthquakesStore.getState().sort()

export default useEarthquakesStore
