import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import QuantectumLogoFull from '../../assets/images/logo-full.png'

const LogoFull = () => {
  const { t } = useTranslationFunction()

  return <img src={QuantectumLogoFull} alt={t('Shared.Logo.Alt')} tw='w-[175px] h-auto' />
}

export default LogoFull
