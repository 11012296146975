import LogoFull from './LogoFull'

const LogoHeader = () => {
  return (
    <div tw='absolute pl-[40px] pt-[37px]'>
      <LogoFull />
    </div>
  )
}

export default LogoHeader
