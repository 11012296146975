import { gisApiInstance } from '../http'
import { Earthquake } from '../interfaces/Earthquake'

export const fetchMacroseismicIntensity = async (earthquake: Earthquake) => {
  const format = 'geojson'

  const url = `https://gis.quantectum.com/hazardmap/Macroseismic Intensity?latitude=${earthquake.latitude}&longitude=${earthquake.longitude}&magnitude=${earthquake.magnitude}&datetime=${earthquake.datetime}&format=${format}`

  return await gisApiInstance.get<any>(url)
}
