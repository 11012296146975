import moment from 'moment'
import { gisApiInstance } from '../http'
import { earthquakeId } from '../interfaces/Earthquake'

export const fetchEarthquakes = async (firstDate: Date, lastDate: Date, provider: string) => {
  const firstDateFormatted = moment(firstDate).format(moment.HTML5_FMT.DATE)
  const lastDateFormatted = moment(lastDate).format(moment.HTML5_FMT.DATE)

  /* eslint-disable camelcase */
  const earthquake_params = {
    start_date: firstDateFormatted,
    end_date: lastDateFormatted,
    min_magnitude: '3.5',
    provider: provider,
    export_format: 'json',
    include_style: true,
    include_datetime_local: true,
    include_extra_description: true,
  }
  /* eslint-enable camelcase */
  const earthquakes = await gisApiInstance.post<any>('/earthquakes/export', earthquake_params)

  for (const eq of earthquakes.data) {
    eq.id = eq.id || earthquakeId(eq)
  }

  return earthquakes
}
