import { useEffect, useRef, useState } from 'react'
import { Layer, MapGeoJSONFeature, MapMouseEvent, Source } from 'react-map-gl/maplibre'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import useMapStore from '../../../state/Map/MapStore'
import { generateZonesGrid } from '../utils'
import { FillLayerSpecification } from 'maplibre-gl'

const layerType = MapLayerType.Zones

const layerProps: FillLayerSpecification = {
  id: layerType,
  source: layerType,
  type: 'fill',
  paint: {
    'fill-color': [
      'case',
      [
        'all',
        ['boolean', ['feature-state', 'subscribed'], false],
        ['boolean', ['feature-state', 'hover'], false],
      ],
      'rgba(0, 200, 0, 0.2)', // Distinct style for subscribed and hovered
      ['boolean', ['feature-state', 'subscribed'], false],
      'rgba(0, 200, 0, 0.1)', // Greenish color for subscribed
      ['boolean', ['feature-state', 'hover'], false],
      'rgba(250, 250, 250, 0.1)', // Light color for hover
      'rgba(0, 0, 0, 0)', // Invisible otherwise
    ],
    'fill-outline-color': [
      'case',
      [
        'all',
        ['boolean', ['feature-state', 'subscribed'], false],
        ['boolean', ['feature-state', 'hover'], false],
      ],
      'rgba(0, 200, 0, 0.8)', // Distinct outline color for subscribed and hovered
      ['boolean', ['feature-state', 'subscribed'], false],
      'rgba(0, 200, 0, 0.5)', // Darker green outline for subscribed
      ['boolean', ['feature-state', 'hover'], false],
      'rgba(255, 255, 255, 0.9)', // White outline for hover
      'rgba(255, 255, 255, 0.025)', // Default outline color
    ],
  },
}

const ZonesLayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [zones, setZones] = useState(generateZonesGrid())
  const hoveredFeatureRef = useRef<MapGeoJSONFeature | null>(null) // Using useRef to persist the value
  const { map, setPopupInfo } = useMapStore()

  useEffect(() => {
    // Logic to fetch and set the always-visible zones
    // setZones(...)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!map) return

    const onClick = (e: MapMouseEvent) => {
      const features = map.queryRenderedFeatures(e.point, { layers: [layerType] })
      if (features.length > 0) {
        const feature = features[0]
        const currentState = map.getFeatureState(feature)
        const newSubscribed = currentState.subscribed !== true
        map.setFeatureState(feature, { subscribed: newSubscribed })
        console.log('click', feature)
      }
    }

    const onMouseMove = (e: MapMouseEvent) => {
      const features = map.queryRenderedFeatures(e.point, { layers: [layerType] })
      if (features.length > 0) {
        const newHoveredFeature = features[0]
        if (newHoveredFeature.id !== hoveredFeatureRef.current?.id) {
          // Unset previous
          if (hoveredFeatureRef.current) {
            map.setFeatureState(hoveredFeatureRef.current, { hover: false })
          }
          hoveredFeatureRef.current = newHoveredFeature

          // Update the map style for the hovered feature
          console.log('move', newHoveredFeature, hoveredFeatureRef.current)
          map.setFeatureState(newHoveredFeature, { hover: true })
        }
      }
    }

    const onMouseLeave = () => {
      if (hoveredFeatureRef.current) {
        console.log('leave', hoveredFeatureRef.current)
        map.setFeatureState(hoveredFeatureRef.current, { hover: false })
        hoveredFeatureRef.current = null
      }
    }

    // Add hover event listeners
    map.on('mousemove', layerType, onMouseMove)
    map.on('mouseleave', layerType, onMouseLeave)
    map.on('click', layerType, onClick)

    return () => {
      // Clean up
      map.off('mousemove', layerType, onMouseMove)
      map.off('mouseleave', layerType, onMouseLeave)
      map.off('click', layerType, onClick)
    }
  }, [map])

  if (isLoading) {
    return null
  }

  return (
    <Source id={layerType} type='geojson' data={zones} generateId={true}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default ZonesLayer
