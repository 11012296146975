import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  useTheme,
} from '@chakra-ui/react'
import { SearchInputType } from '../../../lib/types/SearchInputType'
import SearchInput from '../shared/SearchInput'
import EarthquakesFilterSortSelect from './EarthquakesFilterSortSelect'
import EarthquakesList from './EarthquakesList'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import { useEffect, useRef, useState } from 'react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import EarthquakeMagnitudeFilter from './EarthquakeMagnitudeFilter'
import useReportsStore from '../../../state/Reports/ReportsStore'
import useDateStore from '../../../state/Date/DateStore'

const EarthquakesSidebar = () => {
  const { date } = useDateStore()
  const { isSidebarOpen, toggleSidebar, selected, sortType, minMagnitude } = useEarthquakesStore()
  const { toggleSidebar: toggleReportsSidebar } = useReportsStore()
  const [scrollMode, setScrollMode] = useState('selected')
  const scrollElt = useRef<HTMLDivElement>(null)
  const { t } = useTranslationFunction()
  const theme = useTheme()
  const headerSize = '70px' // Duplication: HeaderMenu
  const sidebarWidth = theme.sizes.lg // Duplication: ReportsOverlay

  const handleScrollClick = () => {
    if (scrollMode === 'top') {
      setScrollMode('selected')
    } else if (scrollMode === 'selected') {
      setScrollMode('top')
    }
  }

  useEffect(() => {
    // Move map controls
    if (isSidebarOpen) {
      const controlElts = [
        document.querySelector('.maplibregl-ctrl-top-right') as HTMLElement,
        document.querySelector('#maplibregl-reset-zoom-icon') as HTMLElement,
      ].filter((elt) => elt)
      controlElts.forEach((elt) => (elt.style.transform = `translateX(-${sidebarWidth})`))

      return () => controlElts.forEach((elt) => (elt.style.transform = 'translateX(0)'))
    }
  })

  useEffect(() => {
    const earthquakesListElt = document.getElementById('earthquakes-sidebar-list')
    if (!scrollElt.current || !earthquakesListElt) return

    if (scrollMode === 'top') {
      scrollElt.current.textContent = t('Earthquakes.Sidebar.ScrollToSelection')
      const topElt = earthquakesListElt.firstElementChild
      topElt?.scrollIntoView({ behavior: 'auto', block: 'center' })
    } else if (scrollMode === 'selected') {
      scrollElt.current.textContent = t('Earthquakes.Sidebar.ScrollToTop')
      const elt = earthquakesListElt.querySelector(`#earthquake-item-${selected?.id}`)
      elt?.scrollIntoView({ behavior: 'auto', block: 'center' })
    }
  }, [scrollMode])

  useEffect(() => {
    if (selected) {
      const eltId = `earthquake-item-${selected.id}`
      setTimeout(() => {
        const elt = document.getElementById(eltId)
        if (elt) {
          const scrollFunc =
            'scrollIntoViewIfNeeded' in elt
              ? (elt as any).scrollIntoViewIfNeeded
              : elt.scrollIntoView
          scrollFunc.bind(elt)({ behavior: 'auto', block: 'center' })

          // TODO: Cleanup with actual styles also // Duplication: see HeaderMenu
          elt.style.borderLeft = '2px solid #333'
          elt.style.borderTopLeftRadius = '4px'
          elt.style.borderBottomLeftRadius = '4px'
          elt.style.backgroundColor = 'rgba(230,230,230,var(--tw-bg-opacity))'
        }
      }, 10)

      return () => {
        const elt = document.getElementById(eltId)
        if (elt) {
          elt.style.borderLeft = 'initial'
          elt.style.borderTopLeftRadius = 'initial'
          elt.style.borderBottomLeftRadius = 'initial'
          elt.style.backgroundColor = 'rgba(243,243,243,var(--tw-bg-opacity))'
        }
      }
    }
  }, [isSidebarOpen, selected, sortType, minMagnitude, date])

  return (
    <Drawer
      variant='nonModal'
      isOpen={isSidebarOpen}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      trapFocus={false}
      placement='right'
      onClose={() => {
        toggleReportsSidebar(false)
        toggleSidebar(false)
      }}
    >
      <DrawerContent
        w={sidebarWidth}
        maxW={sidebarWidth}
        minW={sidebarWidth}
        marginTop={headerSize}
      >
        <DrawerCloseButton />
        <DrawerHeader>
          <div tw='mt-4 text-xl'>{t('Earthquakes.Sidebar.Title')}</div>
        </DrawerHeader>
        <DrawerBody pt={0} mt={0} overflowY='scroll'>
          <div tw='absolute sticky top-0 bg-white z-50 py-0'>
            <div tw='pt-2'>
              <SearchInput usedBy={SearchInputType.Earthquakes} />
            </div>
            <div tw='mt-2'>
              <EarthquakesFilterSortSelect />
            </div>
            <div tw='mt-2'>
              <EarthquakeMagnitudeFilter />
            </div>
            <div tw='flex justify-end py-2'>
              <div ref={scrollElt} tw='select-none' onClick={handleScrollClick}>
                {t('Earthquakes.Sidebar.ScrollToTop')}
              </div>
            </div>
          </div>
          <div>
            <EarthquakesList />
          </div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default EarthquakesSidebar
