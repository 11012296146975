import { useEffect, useState } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl'
import { fetchMaxHorizontalStress } from '../../../lib/services/max-horizontal-stress.service'
import useDateStore from '../../../state/Date/DateStore'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.MaxHorizontalStress

const layerProps: LineLayer = {
  id: layerType,
  type: 'line',
  paint: {
    'line-color': '#F75D59',
    'line-width': 2.5,
    'line-opacity': ['+', 0.1, ['/', ['sqrt', ['get', 'green']], 7]],
  },
}

const MaxHorizontalStressLayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [maxHorizontalStress, setMaxHorizontalStress] = useState('')
  const { date } = useDateStore()

  useEffect(() => {
    const getMaxHorizontalStress = async () => {
      const response = await fetchMaxHorizontalStress(date)
      setMaxHorizontalStress(response.data)
      setIsLoading(false)
    }
    getMaxHorizontalStress()
  }, [date])

  if (isLoading) {
    return null
  }

  return (
    <Source type='geojson' data={maxHorizontalStress}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default MaxHorizontalStressLayer
