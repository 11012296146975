import { withConditionalFeedback } from '../../Shared/withConditionalFeedback'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import EarthquakeItem from './EarthquakeItem'
import { Earthquake } from '../../../lib/interfaces/Earthquake'

const EarthquakesList = () => {
  const { data, loading, hasErrors } = useEarthquakesStore()

  return (
    <BaseEarthquakesListwithConditionalFeedback
      data={data}
      isLoading={loading}
      hasErrors={hasErrors}
    />
  )
}

interface BaseEarthquakesListProps {
  data: Earthquake[]
  isLoading: boolean
  hasErrors: boolean
}

const BaseEarthquakesList = (props: BaseEarthquakesListProps) => {
  return (
    <div id='earthquakes-sidebar-list'>
      {props.data.map((earthquake: Earthquake) => (
        <EarthquakeItem key={earthquake.id} {...earthquake} />
      ))}
    </div>
  )
}

const BaseEarthquakesListwithConditionalFeedback = withConditionalFeedback(BaseEarthquakesList)

export default EarthquakesList
