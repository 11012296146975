import { Select } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { SortType } from '../../../lib/types/SortType'
import useReportsStore from '../../../state/Reports/ReportsStore'

const ReportsSortSelect = () => {
  const [value, setValue] = useState('')
  const { sort } = useReportsStore()
  const { t } = useTranslationFunction()

  const handleSortTypeChange = (event: any) => {
    const newValue = event.target.value
    setValue(newValue)
    sort(newValue)
  }

  return (
    <Select
      value={value}
      onChange={handleSortTypeChange}
      placeholder={t('Reports.Sidebar.Sorting.DefaultOption')}
      rounded='none'
    >
      <option value={SortType.DateRangeAsc}>
        {t('Reports.Sidebar.Sorting.EarlierDateRangeFirstOption')}
      </option>
      <option value={SortType.DateRangeDsc}>
        {t('Reports.Sidebar.Sorting.LatestDateRangeFirstOption')}
      </option>
      <option value={SortType.MagnitudeAsc}>
        {t('Reports.Sidebar.Sorting.MinimumMagnitudeFirstOption')}
      </option>
      <option value={SortType.MagnitudeDsc}>
        {t('Reports.Sidebar.Sorting.MaximumMagnitudeFirst')}
      </option>
    </Select>
  )
}

export default ReportsSortSelect
