import { MapLayerType } from '../../lib/types/MapLayerType'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Map } from 'maplibre-gl'
import { rasterLayers } from '../../components/Map/Layers/Shared/RasterLayerCommon'

export type LayerActiveState = Record<MapLayerType, boolean>
export type LayerProps = { [key: string]: any }

interface MapState {
  map: Map | null
  setMap: (map: Map) => void
  isLayerActive: LayerActiveState
  toggleMapLayer: (layer: MapLayerType, desiredState?: boolean) => void
  incompatibleLayers: (layer: MapLayerType) => MapLayerType[]
  popupInfo: { [key: string]: any } | null
  setPopupInfo: (popupInfo: { [key: string]: any } | null) => void
  layerProps: { [layerType: MapLayerType | string]: LayerProps } //
  setLayerProps: (layerType: MapLayerType, props: LayerProps) => void
}

const useMapStore = create<MapState>()(
  devtools(
    (set, get) => ({
      map: null,
      setMap: (map) => set({ map }),
      popupInfo: null,
      setPopupInfo: (info) => set({ popupInfo: info }),
      isLayerActive: {
        [MapLayerType.TectonicWaves]: false,
        [MapLayerType.TectonicTraction]: true,
        [MapLayerType.SmoothedSeismicity]: false,
        [MapLayerType.TectonicFaults]: true,
        [MapLayerType.MaxHorizontalStress]: false,
        [MapLayerType.CriticalRegions]: true,
        [MapLayerType.Earthquakes]: true,
        [MapLayerType.DynamicSeismicPotential]: false,
        [MapLayerType.AverageSeismicPotential]: false,
        [MapLayerType.ShearStress]: false,
        [MapLayerType.MacroseismicIntensity]: true,
        [MapLayerType.MacroseismicIntensityGrey]: true,
        [MapLayerType.MacroseismicIntensityLabels]: true,
        [MapLayerType.StockExchanges]: true,
        [MapLayerType.Dams]: false,
        [MapLayerType.NuclearPowerPlants]: true,
        [MapLayerType.CountryStats]: true,
        [MapLayerType.ZonesIndonesia]: false,
        [MapLayerType.GeologyIndonesia]: false,
        [MapLayerType.Zones]: false,
        [MapLayerType.Nightshade]: false,
      },
      toggleMapLayer: (layer: MapLayerType, desiredState?: boolean) => {
        set((state) => {
          // Duplication: EarthquakeStore
          const isLayerActive = state.isLayerActive[layer]
          let newisLayerActive = !isLayerActive
          if (typeof desiredState === 'boolean') {
            newisLayerActive = desiredState
          }

          const inactivateIncompatibleLayers = newisLayerActive
            ? Object.fromEntries(
                get()
                  .incompatibleLayers(layer)
                  .map((layer) => [layer, false]),
              )
            : {}

          return {
            isLayerActive: {
              ...state.isLayerActive,
              ...inactivateIncompatibleLayers,
              [layer]: newisLayerActive,
            },
          }
        })
      },
      incompatibleLayers: (layer: MapLayerType) => {
        const output = []
        const rasterSet = new Set(rasterLayers)
        if (rasterSet.has(layer)) {
          output.push(...[...rasterSet].filter((raster) => raster !== layer))
        }
        return output
      },
      layerProps: {},
      setLayerProps: (layerType, props) => {
        set({
          layerProps: {
            ...get().layerProps,
            [layerType]: {
              ...get().layerProps[layerType],
              ...props,
            },
          },
        })
      },
    }),
    {
      name: 'map-storage',
    },
  ),
)

export default useMapStore
