import { Layer, LayerProps, Source } from 'react-map-gl/maplibre'
import { circleRadiusKm, damsImages, loadMapImages, reprocessDamsGeoJSON } from '../utils'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { useEffect, useState } from 'react'
import GeoJSON from 'geojson'
import useMapStore from '../../../state/Map/MapStore'
import { MapLayerMouseEvent } from 'maplibre-gl'
import { fetchDams } from '../../../lib/services/dams.service'
import {
  infrastructureLayerProps,
  infrastructureSourceProps,
} from './Shared/InfrastructureLayerCommon'
import useAuthStore from '../../../state/Auth/AuthStore'

const layerType = MapLayerType.Dams

const DamsLayer = () => {
  const [dams, setDams] = useState<GeoJSON.FeatureCollection | null>(null)
  const { map, setPopupInfo } = useMapStore()
  const { isFreeUser } = useAuthStore()

  if (isFreeUser()) {
    return null
  }

  const attribution =
    'Dams by <a href="https://www.geonames.org/" target="_blank">GeoNames</a>, <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC-BY 4.0</a>'

  useEffect(() => {
    if (!map) return

    const fetchAll = async () => {
      const response = await fetchDams()
      await loadMapImages(map, damsImages)
      setDams(response.data)
    }
    setTimeout(fetchAll, 1)

    const onClickPopup = (event: MapLayerMouseEvent) => {
      if (!event.features) return

      const properties = event.features[0].properties
      const content = (
        <div tw='p-4'>
          <div tw='text-lg text-center mb-4'>
            <b>{properties.name}</b>
          </div>
          <div>Country: {properties.country}</div>
        </div>
      )
      setPopupInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        content,
      })
    }
    map.on('click', layerType, onClickPopup)
    return () => {
      map.off('click', layerType, onClickPopup)
    }
  }, [map])

  // useNamedEventHandler(layerProps.id as string, (feature) => {
  // nop
  // })

  return (
    <Source
      {...infrastructureSourceProps()}
      attribution={attribution}
      data={reprocessDamsGeoJSON(dams)}
    >
      <Layer {...infrastructureLayerProps(layerType, 1.7)} />
    </Source>
  )
}

export default DamsLayer
