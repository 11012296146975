import { useEffect, useState } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl/maplibre'
import { fetchTectonicWaves } from '../../../lib/services/tectonic-waves.service'
import useDateStore from '../../../state/Date/DateStore'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.TectonicWaves

const layerProps: LineLayer = {
  id: layerType,
  source: layerType,
  type: 'line',
  paint: {
    'line-color': ['get', 'color'],
    'line-width': ['get', 'weight'],
    'line-opacity': ['get', 'opacity'],
  },
}

const TectonicWavesLayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [tectonicWaves, setTectonicWaves] = useState('')
  const { date } = useDateStore()

  useEffect(() => {
    const getTectonicWaves = async () => {
      const response = await fetchTectonicWaves(date)
      setTectonicWaves(response.data)
      setIsLoading(false)
    }
    getTectonicWaves()
  }, [date])

  if (isLoading) {
    return null
  }

  return (
    <Source type='geojson' data={tectonicWaves}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default TectonicWavesLayer
