import ConfirmAccountForm from '../components/Auth/ConfirmAccountForm'

const ConfirmAccountPage = () => {
  return (
    <div tw='bg-white w-[460px] h-auto'>
      <div tw='p-[50px]'>
        <ConfirmAccountForm />
      </div>
    </div>
  )
}

export default ConfirmAccountPage
