import { create } from 'zustand'

export type EventHandler = (value: any) => void

interface NamedEventHandlersState {
  handlers: {
    [name: string]: EventHandler[]
  }
  addHandler: (name: string, handler: EventHandler) => void
  removeHandler: (name: string, handler: EventHandler) => void
}

const useNamedEventHandlersStore = create<NamedEventHandlersState>((set) => ({
  handlers: {},
  addHandler: (name, handler) =>
    set((state) => ({
      handlers: {
        ...state.handlers,
        [name]: [...(state.handlers[name] || []), handler],
      },
    })),
  removeHandler: (name, handler) =>
    set((state) => ({
      handlers: {
        ...state.handlers,
        [name]: (state.handlers[name] || []).filter((h) => h !== handler),
      },
    })),
}))

export default useNamedEventHandlersStore
