/* eslint-disable react/no-children-prop */
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

interface MarkdownRenderProps {
  content: any
}

const MarkdownRender = ({ content }: MarkdownRenderProps) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ ...props }) => <h1 tw='text-3xl font-bold ' {...props} />,
        h2: ({ ...props }) => <h1 tw='text-2xl font-bold mb-2' {...props} />,
        h3: ({ ...props }) => <h1 tw='text-xl font-bold mb-2' {...props} />,
        h4: ({ ...props }) => <h1 tw='text-lg font-bold mb-2' {...props} />,
        h5: ({ ...props }) => <h1 tw='text-base font-bold mb-2' {...props} />,
        h6: ({ ...props }) => <h1 tw='text-base font-bold mb-2' {...props} />,
        ul: ({ ...props }) => <ul tw='list-disc ml-6 mb-4' {...props} />,
        ol: ({ ...props }) => <ol tw='list-decimal ml-6 mb-4' {...props} />,
        a: ({ ...props }) => <a target='_blank' rel='noopener noreferrer' {...props} />,
        p: ({ ...props }) => <p tw='mb-4' {...props} />,
      }}
      children={content}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    />
  )
}

export default MarkdownRender
