import { Link, Outlet } from 'react-router-dom'
import LogoHeader from '../Auth/LogoHeader'

const AuthLayout = () => {
  return (
    <div tw='min-h-screen bg-quantectumAuthGray'>
      <Link to='/login'>
        <LogoHeader />
      </Link>
      <div tw='flex flex-col justify-center items-center h-screen'>
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout
