import { Button, useDisclosure } from '@chakra-ui/react'
import { useReportDownload } from '../../../hooks/useReportDownload'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { Report } from '../../../lib/interfaces/Report'
import { QuantectumButtonStyles } from '../../../styles/shared/QuantectumButtonStyles'
import ReportItemDetailsModal from './ReportItemDetailsModal'

const ReportItem = (props: Report) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { downloadReport, isLoading } = useReportDownload()
  const { t } = useTranslationFunction()

  const handleDownloadReportClick = (id: number, name: string) => {
    downloadReport(id, name)
  }

  return (
    <div tw='flex overflow-hidden bg-quantectumWhiteGray pl-4 pr-8 py-6 mb-6'>
      <div tw='w-1/5'>
        <div tw='flex'>
          <div>{props.magnitude}</div>
        </div>
      </div>
      <div tw='w-4/5'>
        <div tw='flex flex-col'>
          <div tw='flex justify-start items-center'>
            <div tw='text-lg font-semibold py-1'>{props.name}</div>
          </div>
          <div tw='my-4'>
            <span tw='mr-2 font-medium'>{t('Reports.Sidebar.DateText')}</span>
            <span>
              {new Date(props.dateFrom).toLocaleDateString()} -
              {new Date(props.dateTo).toLocaleDateString()}
            </span>
          </div>
          <div tw='space-y-2'>
            <Button {...QuantectumButtonStyles} onClick={onOpen} w='full' py={4}>
              {t('Reports.Sidebar.ViewDetailsButtonText')}
            </Button>
            <Button
              isLoading={isLoading}
              {...QuantectumButtonStyles}
              w='full'
              py={4}
              onClick={() => handleDownloadReportClick(props.id, props.name)}
            >
              {t('Reports.Sidebar.DownloadReportButtonText')}
            </Button>
          </div>
        </div>
      </div>
      <ReportItemDetailsModal isOpen={isOpen} onClose={onClose} report={props} />
    </div>
  )
}

export default ReportItem
