import { Layer, Source } from 'react-map-gl/maplibre'
import useDateStore from '../../../state/Date/DateStore'
import { rasterLayerProps, rasterSourceProps } from './Shared/RasterLayerCommon'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.DynamicSeismicPotential

const DynamicSeismicPotentialLayer = () => {
  const baseUrl = 'https://site-data.quantectum.com/'
  const layerName = 'potentials_dynamic_seismic'
  const tileUrl = `${baseUrl}/${layerName}.png`

  return (
    <Source key={tileUrl} {...rasterSourceProps(layerType, tileUrl)}>
      <Layer {...rasterLayerProps(layerType)} />
    </Source>
  )
}

export default DynamicSeismicPotentialLayer
