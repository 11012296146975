import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  getDateAddedByDays,
  getDateSubtractedByDays,
} from '../../components/Header/DatePicker/DateHelper'
import useAuthStore from '../Auth/AuthStore'
import moment from 'moment'

interface DateState {
  date: Date
  firstDate: Date
  lastDate: Date
  setDate: (date: Date) => void
  reset: () => void
}

const initialState = {
  date: new Date(),
  firstDate: getDateSubtractedByDays(new Date(), 3),
  lastDate: getDateAddedByDays(new Date(), 3),
}

const useDateStore = create<DateState>()(
  devtools(
    (set) => ({
      ...initialState,
      setDate: (date: Date) => {
        const { isFreeUser } = useAuthStore.getState()
        if (isFreeUser()) {
          const now = new Date()
          const minDate = getDateSubtractedByDays(now, 14)
          const maxDate = getDateAddedByDays(now, 14)
          if (moment(date).isBefore(minDate)) date = minDate
          if (moment(date).isAfter(maxDate)) date = maxDate
        }

        set(() => ({
          date,
          firstDate: getDateSubtractedByDays(date, 3),
          lastDate: getDateAddedByDays(date, 3),
        }))
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: 'date-storage',
    },
  ),
)

export default useDateStore
