import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import { Region } from '../../lib/interfaces/Region'

interface SubscribedRegionsListProps {
  regions: Region[] | undefined
}

const SubscribedRegionsList = ({ regions }: SubscribedRegionsListProps) => {
  const { t } = useTranslationFunction()

  return (
    <div>
      <div>{t('Profile.Page.SubscribedRegionsTitle')}</div>
      {regions && regions.length > 0 && (
        <>
          {regions.map((region: Region) => (
            <div key={region.label} tw='bg-quantectumWhiteGray px-4 py-4 mt-4'>
              {region.label}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default SubscribedRegionsList
