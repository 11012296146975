import BaseMap from '../components/Map/BaseMap'
import AlarmsNotificationsSidebar from '../components/Sidebars/AlertsNotifications/AlertsNotificationsSidebar'
import ReportsSidebar from '../components/Sidebars/Reports/ReportsSidebar'
import EarthquakesSidebar from '../components/Sidebars/Earthquakes/EarthquakesSidebar'
import ReportsOverlay from '../components/Sidebars/Reports/ReportsOverlay'

const MainPage = () => {
  return (
    <>
      <BaseMap />
      <ReportsOverlay />
      <EarthquakesSidebar />
      <AlarmsNotificationsSidebar />
      <ReportsSidebar />
    </>
  )
}

export default MainPage
