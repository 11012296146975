import React, { FC } from 'react'
import 'twin.macro'
import { Earthquake } from '../../../lib/interfaces/Earthquake'

type ComponentProps = {
  earthquake: Earthquake
}

const EarthquakeMagnitudeBox = ({ earthquake }: ComponentProps) => {
  return (
    <div
      tw='py-1 px-2 rounded-md'
      style={{
        backgroundColor: earthquake.color,
        boxShadow: '1px 1px 3px #AAA',
        fontWeight: 500,
      }}
    >
      {earthquake.magnitude.toFixed(1)}
    </div>
  )
}

export default EarthquakeMagnitudeBox
