import 'maplibre-gl/dist/maplibre-gl.css'
import Map, {
  NavigationControl,
  AttributionControl,
  MapSourceDataEvent,
  MapGeoJSONFeature,
} from 'react-map-gl/maplibre'
import { MapLayerMouseEvent, MapLibreEvent } from 'maplibre-gl'
import MapLayerToggleList from './MapLayerToggle/MapLayerToggleList'
import ResetZoomControl from './ResetZoomControl'
import useNamedEventHandlersStore from '../../state/EventHandlers/NamedEventHandlersStore'
import useMapStore from '../../state/Map/MapStore'
import EarthquakesLayer from './Layers/EarthquakesLayer'
import TectonicFaultsLayer from './Layers/TectonicFaultsLayer'
import TectonicWavesLayer from './Layers/TectonicWavesLayer'
import TectonicTractionLayer from './Layers/TectonicTractionLayer'
import MaxHorizontalStressLayer from './Layers/MaxHorizontalStressLayer'
import CriticalRegionsLayerCircles from './Layers/CriticalRegionsLayerCircles'
import { MapLayerType } from '../../lib/types/MapLayerType'
import { transformMapboxRequest } from './utils'
import SmoothedSeismicityLayer from './Layers/SmoothedSeismicityLayer'
import useLocalStorageState from 'use-local-storage-state'
import DynamicSeismicPotentialLayer from './Layers/DynamicSeismicPotentialLayer'
import NuclearPowerPlantsLayer from './Layers/NuclearPowerPlantsLayer'
import StockExchangesLayer from './Layers/StockExchangesLayer'
import { Popup, ScaleControl } from 'react-map-gl'
import ShearStressLayer from './Layers/ShearStressLayer'
import CriticalRegionsLayerSquares from './Layers/CriticalRegionsLayerSquares'
import AverageSeismicPotentialLayer from './Layers/AverageSeismicPotential'
import DamsLayer from './Layers/DamsLayer'
import MacroseismicIntensityLayer from './Layers/MacroseismicIntensityLayer'
import NightshadeLayer from './Layers/NightshadeLayer'
import CountryStatsLayer from './Layers/CountryStatsLayer'
import ZonesIndonesiaLayer from './Layers/ZonesIndonesiaLayer'
import GeologyIndonesiaLayer from './Layers/GeologyIndonesiaLayer'
import ZonesLayer from './Layers/ZonesLayer'

const BaseMap = () => {
  const { map, setMap, isLayerActive, popupInfo, setPopupInfo, layerProps } = useMapStore()
  const { handlers: layerEventHandlers } = useNamedEventHandlersStore()
  const initialViewport = {
    latitude: 40,
    longitude: 10,
    zoom: 3.5,
    bearing: 0,
    pitch: 0,
  }
  const [viewport, setViewport] = useLocalStorageState('mapViewport', {
    defaultValue: initialViewport,
  })

  // Workaround: Reorder layers according to MapLayerType ordering
  const reorderLayers = () => {
    if (!map) return
    if (!map.getStyle()?.layers) return

    let previousLayerId = null
    const currentLayerIds = map.getStyle().layers.map((layer) => layer.id)
    for (const layerId of Object.values(MapLayerType).reverse()) {
      if (currentLayerIds.includes(layerId)) {
        if (previousLayerId) {
          map.moveLayer(layerId, previousLayerId)
        }
        previousLayerId = layerId
      }
    }
  }

  const onMapLoad = (event: MapLibreEvent) => {
    // Set map reference
    setMap(event.target)
    setTimeout(reorderLayers, 1)
    setTimeout(reorderLayers, 100)
    setTimeout(reorderLayers, 500)
    setTimeout(reorderLayers, 1000)
  }

  // Handles map events via the NamedEventHandlersStore, using layersId as keys
  const onMapClick = (mouseEvent: MapLayerMouseEvent) => {
    const clickedFeatures = map?.queryRenderedFeatures(mouseEvent.point)
    const clickedFeaturesByLayer: { [layerId: string]: MapGeoJSONFeature[] } = {}

    clickedFeatures?.forEach((feature) => {
      const layerId = feature.layer.id
      clickedFeaturesByLayer[layerId] = (clickedFeaturesByLayer[layerId] ?? []).concat([feature])
    })

    // Go over layers, and send features to their respective handlers, or null otherwise
    Object.keys(MapLayerType).forEach((layerId) => {
      if (clickedFeaturesByLayer[layerId]) {
        clickedFeaturesByLayer[layerId].forEach((feature) => {
          layerEventHandlers[layerId]?.forEach((handler) => handler(feature))
        })
      } else {
        // Send null to all handlers without a clicked feature
        layerEventHandlers[layerId]?.forEach((handler) => handler(null))
      }
    })
  }

  // Update viewport state after map move
  const onMoveEnd = () => {
    if (!map) return
    setViewport({
      latitude: map.getCenter().lat,
      longitude: map.getCenter().lng,
      zoom: map.getZoom(),
      bearing: map.getBearing(),
      pitch: map.getPitch(),
    })
    setTimeout(reorderLayers, 1)
  }

  const onSourceData = (event: MapSourceDataEvent) => setTimeout(reorderLayers, 1)

  return (
    <Map
      initialViewState={viewport}
      interactiveLayerIds={['data']}
      mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      style={{ width: '100%', height: '100%' }}
      onLoad={onMapLoad}
      onClick={onMapClick}
      onSourceData={onSourceData}
      onMoveEnd={onMoveEnd}
      attributionControl={false}
      transformRequest={transformMapboxRequest}
    >
      {isLayerActive[MapLayerType.TectonicTraction] && <TectonicTractionLayer />}
      {isLayerActive[MapLayerType.TectonicFaults] && <TectonicFaultsLayer />}
      {isLayerActive[MapLayerType.SmoothedSeismicity] && <SmoothedSeismicityLayer />}
      {isLayerActive[MapLayerType.TectonicWaves] && <TectonicWavesLayer />}
      {isLayerActive[MapLayerType.MaxHorizontalStress] && <MaxHorizontalStressLayer />}
      {isLayerActive[MapLayerType.Earthquakes] && <EarthquakesLayer />}
      {isLayerActive[MapLayerType.CriticalRegions] &&
        (layerProps[MapLayerType.CriticalRegions]?.shape === 'square' ? (
          <CriticalRegionsLayerSquares />
        ) : (
          <CriticalRegionsLayerCircles />
        ))}
      {isLayerActive[MapLayerType.DynamicSeismicPotential] && <DynamicSeismicPotentialLayer />}
      {isLayerActive[MapLayerType.AverageSeismicPotential] && <AverageSeismicPotentialLayer />}
      {isLayerActive[MapLayerType.ShearStress] && <ShearStressLayer />}
      {isLayerActive[MapLayerType.MacroseismicIntensity] && <MacroseismicIntensityLayer />}
      {isLayerActive[MapLayerType.NuclearPowerPlants] && <NuclearPowerPlantsLayer />}
      {isLayerActive[MapLayerType.Dams] && <DamsLayer />}
      {isLayerActive[MapLayerType.StockExchanges] && <StockExchangesLayer />}
      {isLayerActive[MapLayerType.CountryStats] && <CountryStatsLayer />}
      {isLayerActive[MapLayerType.ZonesIndonesia] && <ZonesIndonesiaLayer />}
      {isLayerActive[MapLayerType.GeologyIndonesia] && <GeologyIndonesiaLayer />}
      {isLayerActive[MapLayerType.Nightshade] && <NightshadeLayer />}
      {isLayerActive[MapLayerType.Zones] && <ZonesLayer />}

      {popupInfo && (
        <Popup
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick
          closeButton={false}
          onClose={() => setPopupInfo(null)}
        >
          <div style={{ backgroundColor: 'rgba(230,230,230,0.9)' }}>{popupInfo.content}</div>
        </Popup>
      )}

      <NavigationControl position='top-right' showCompass={false} />
      <ResetZoomControl />
      <ScaleControl maxWidth={200} unit='metric' />
      <MapLayerToggleList />
      <AttributionControl compact={true} customAttribution='© Quantectum AG' />
    </Map>
  )
}

export default BaseMap
