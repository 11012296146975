import { useEffect, useState } from 'react'
import { Layer, LineLayer, Source } from 'react-map-gl/maplibre'
import { fetchTectonicFaults } from '../../../lib/services/tectonic-faults.service'
import { MapLayerType } from '../../../lib/types/MapLayerType'

const layerType = MapLayerType.TectonicFaults

const layerProps: LineLayer = {
  id: layerType,
  source: layerType,
  type: 'line',
  paint: {
    'line-color': '#FFFF00',
    'line-width': 0.9,
  },
}

const TectonicFaultsLayer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [tectonicFaults, setTectonicFaults] = useState('')

  useEffect(() => {
    const getTectonicFaults = async () => {
      const response = await fetchTectonicFaults()
      setTectonicFaults(response.data)
      setIsLoading(false)
    }
    getTectonicFaults()
  }, [])

  if (isLoading) {
    return null
  }

  const attribution =
    'Faults by <a href="https://github.com/GEMScienceTools/gem-global-active-faults" target="_blank">GEM</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">CC BY-SA 4.0</a>'

  return (
    <Source type='geojson' attribution={attribution} data={tectonicFaults}>
      <Layer {...layerProps} />
    </Source>
  )
}

export default TectonicFaultsLayer
