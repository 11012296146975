import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { AlertNotification } from '../../../lib/interfaces/AlertNotification'
import { Region } from '../../../lib/interfaces/Region'
import { AlertNotificationType } from '../../../lib/types/AlertNotificationType'
import { QuantectumButtonStyles } from '../../../styles/shared/QuantectumButtonStyles'
import MarkdownRender from '../../Shared/MarkdownRender'
import AlertIcon from './Icons/AlertIcon'
import NotificationIcon from './Icons/NotificationIcon'

interface AlertNotificationItemDetailsModalProps {
  isOpen: boolean
  onClose: any
  alertNotification: AlertNotification
}

const AlertNotificationItemDetailsModal = ({
  isOpen,
  onClose,
  alertNotification,
}: AlertNotificationItemDetailsModalProps) => {
  const { t } = useTranslationFunction()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset='slideInBottom'
      scrollBehavior='inside'
      size='3xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div tw='flex items-center'>
            {alertNotification.type === AlertNotificationType.Notification ? (
              <NotificationIcon />
            ) : (
              <AlertIcon />
            )}
            <span tw='ml-4'>{alertNotification.name}</span>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MarkdownRender content={alertNotification.content} />
          <div tw='my-6'>
            <span tw='mr-2 font-bold'>{t('AlertNotification.Sidebar.DateText')}</span>
            <span>
              {new Date(alertNotification.dateFrom).toLocaleDateString()} -
              {new Date(alertNotification.dateTo).toLocaleDateString()}
            </span>
          </div>
          <div tw='my-6'>
            <span tw='mr-2 font-bold'>{t('AlertNotification.Sidebar.AffectedRegionsText')}</span>
            <ul tw='list-disc ml-4'>
              {alertNotification.regions.map((region: Region) => (
                <li key={region.value.latlng}>{region.label}</li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button {...QuantectumButtonStyles} mr={3} onClick={onClose}>
            {t('AlertNotification.Sidebar.CloseButtonText')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AlertNotificationItemDetailsModal
