import { Layer, Source } from 'react-map-gl/maplibre'
import useDateStore from '../../../state/Date/DateStore'
import moment from 'moment'
import { MapLayerType } from '../../../lib/types/MapLayerType'
import { rasterLayerProps, rasterSourceProps } from './Shared/RasterLayerCommon'

const layerType = MapLayerType.ShearStress

const ShearStressLayer = () => {
  const { date } = useDateStore()

  const baseUrl = 'https://site-data.quantectum.com'
  const layerName = 'shear_stress'
  const tileUrl = `${baseUrl}/${moment(date).format('YYYYMMDD')}_${layerName}.png`

  return (
    <Source key={tileUrl} {...rasterSourceProps(layerType, tileUrl)}>
      <Layer {...rasterLayerProps(layerType)} />
    </Source>
  )
}

export default ShearStressLayer
