import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import { User } from '../../lib/interfaces/User'

interface ProfileFormValues {
  firstName: string
  lastName: string
  company: string
  address: string
  email: string
}

interface ProfileFormProps {
  currentUser: User | null
}

const ProfileForm = ({ currentUser }: ProfileFormProps) => {
  const { t } = useTranslationFunction()

  const { register } = useForm<ProfileFormValues>({
    defaultValues: {
      firstName: currentUser?.firstname,
      lastName: currentUser?.lastname,
      company: currentUser?.company,
      address: currentUser?.address,
      email: currentUser?.email,
    },
  })

  return (
    <form>
      <div tw='flex flex-wrap -mx-4'>
        <div tw='w-full md:w-1/2 px-4'>
          <FormControl id='firstName'>
            <FormLabel fontWeight='normal'>{t('Profile.Form.FirstNameLabel')}</FormLabel>
            <Input isReadOnly={true} type='text' {...register('firstName')} />
          </FormControl>
          <FormControl mt={4} id='company'>
            <FormLabel fontWeight='normal'>{t('Profile.Form.CompanyLabel')}</FormLabel>
            <Input isReadOnly={true} type='text' {...register('company')} />
          </FormControl>
          <FormControl mt={4} id='email'>
            <FormLabel fontWeight='normal'>{t('Profile.Form.EmailLabel')}</FormLabel>
            <Input isReadOnly={true} type='email' {...register('email')} />
          </FormControl>
        </div>
        <div tw='w-full md:w-1/2 px-4'>
          <FormControl mt={{ base: 4, md: 0 }} id='lastName'>
            <FormLabel fontWeight='normal'>{t('Profile.Form.LastNameLabel')}</FormLabel>
            <Input isReadOnly={true} type='text' {...register('lastName')} />
          </FormControl>
          <FormControl mt={4} id='address'>
            <FormLabel fontWeight='normal'>{t('Profile.Form.AddressLabel')}</FormLabel>
            <Input isReadOnly={true} type='text' {...register('address')} />
          </FormControl>
        </div>
      </div>
    </form>
  )
}

export default ProfileForm
