import React from 'react'
import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import LoadingSpinner from './LoadingSpinner'

export const withConditionalFeedback = (Component: React.ComponentType<any>) => {
  return (props: { isLoading: boolean; hasErrors: boolean; data: Array<any> }) => {
    const { isLoading, hasErrors, data } = props
    const { t } = useTranslationFunction()

    if (isLoading) {
      return <LoadingSpinner />
    }

    if (hasErrors) {
      return <p tw='text-red-500'>{t('Shared.Sidebar.DataRetrievingErrorText')}</p>
    }

    if (data.length === 0) {
      return <p>{t('Shared.Sidebar.NoEntriesText')}</p>
    }

    return <Component {...props} />
  }
}
