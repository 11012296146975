import { SmallCloseIcon } from '@chakra-ui/icons'
import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useState } from 'react'
import { SearchInputType } from '../../../lib/types/SearchInputType'
import useAlarmsNotificationsStore from '../../../state/AlertsNotifications/AlertsNotificationsStore'
import useReportsStore from '../../../state/Reports/ReportsStore'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'

interface SearchInputProps {
  usedBy: SearchInputType
}

const SearchInput = ({ usedBy }: SearchInputProps) => {
  const { search: searchEarthquakes, searchTerm: searchTermEarthquakes } = useEarthquakesStore()
  const { search: searchAlarmsNotifications } = useAlarmsNotificationsStore()
  const { search: searchReports } = useReportsStore()
  const initialValue = () => {
    switch (usedBy) {
      case SearchInputType.AlertNotification:
        return ''
        break
      case SearchInputType.Reports:
        return ''
        break
      case SearchInputType.Earthquakes:
        return searchTermEarthquakes
    }
  }
  const [value, setValue] = useState(initialValue())

  const handleChange = (event: any) => {
    const searchValue = event.target.value
    setValue(searchValue)
    performSearch(searchValue)
  }

  const handleCancelSearchClick = () => {
    setValue('')
    performSearch('')
  }

  const performSearch = (searchValue: string) => {
    switch (usedBy) {
      case SearchInputType.AlertNotification:
        searchAlarmsNotifications(searchValue)
        break
      case SearchInputType.Reports:
        searchReports(searchValue)
        break
      case SearchInputType.Earthquakes:
        searchEarthquakes(searchValue)
    }
  }

  return (
    <InputGroup>
      <Input placeholder='Search term...' value={value} onChange={handleChange} />
      <InputRightElement>
        <IconButton
          aria-label='Cancel search'
          icon={<SmallCloseIcon />}
          size='sm'
          onClick={handleCancelSearchClick}
        ></IconButton>
      </InputRightElement>
    </InputGroup>
  )
}

export default SearchInput
