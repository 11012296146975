import { useEffect } from 'react'
import { Report } from '../../../lib/interfaces/Report'
import useDateStore from '../../../state/Date/DateStore'
import useReportsStore from '../../../state/Reports/ReportsStore'
import { withConditionalFeedback } from '../../Shared/withConditionalFeedback'
import ReportItem from './ReportItem'

const ReportsList = () => {
  const { data, getAll, loading, hasErrors } = useReportsStore()
  const { firstDate, lastDate } = useDateStore()

  useEffect(() => {
    getAll(firstDate, lastDate)
  }, [])

  return (
    <BaseReportsListwithConditionalFeedback data={data} isLoading={loading} hasErrors={hasErrors} />
  )
}

interface BaseReportsListProps {
  data: Array<Report>
  isLoading: boolean
  hasErrors: boolean
}

const BaseReportsList = (props: BaseReportsListProps) => {
  return (
    <div>
      {props.data.map((report: Report) => (
        <ReportItem key={report.id} {...report} />
      ))}
    </div>
  )
}

const BaseReportsListwithConditionalFeedback = withConditionalFeedback(BaseReportsList)

export default ReportsList
