import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { Earthquake } from '../../../lib/interfaces/Earthquake'
import moment from 'moment'
import useMapStore from '../../../state/Map/MapStore'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'
import { MouseEventHandler } from 'react'
import EarthquakeMagnitudeBox from './EarthquakeMagnitudeBox'
import useReportsStore from '../../../state/Reports/ReportsStore'
import FreeFeature from '../../Shared/FreeFeature'
import NonFreeFeature from '../../Shared/NonFreeFeature'

const EarthquakeItem = (earthquake: Earthquake) => {
  const { t } = useTranslationFunction()
  const { map } = useMapStore()
  const { select } = useEarthquakesStore()
  const { toggleSidebar: toggleReportsSidebar } = useReportsStore()

  const momentDateTime = moment(earthquake.datetime, 'YYYY-MM-DD HH:mm:ss')
  const formattedDate = momentDateTime.format('YYYYMMDD_HHmmss')
  const [latitude1, longitude1, magnitude1] = [
    earthquake.latitude.toFixed(1),
    earthquake.longitude.toFixed(1),
    earthquake.magnitude.toFixed(1),
  ]
  const imageUrl = `https://site-data.quantectum.com/${formattedDate}_${latitude1}_${longitude1}_${magnitude1}_globe.png`

  const handleEarthquakeClick = () => {
    map?.flyTo({ center: [earthquake.longitude, earthquake.latitude] })
    select(earthquake)
  }

  const handleReportButtonClick: MouseEventHandler = (event) => {
    toggleReportsSidebar(true)
  }

  return (
    <div
      id={`earthquake-item-${earthquake.id}`}
      tw='flex flex-col overflow-hidden bg-quantectumWhiteGray px-4 py-3.5 mb-3.5'
      onClick={handleEarthquakeClick}
    >
      <div tw='flex justify-start items-center mb-1.5'>
        <EarthquakeMagnitudeBox earthquake={earthquake} />
        <div tw='ml-4 text-lg font-semibold'>
          {earthquake.extra?.description ?? earthquake.description}
        </div>
      </div>
      <div tw='flex flex-row mt-1'>
        <div tw='flex self-start justify-center ml-1 mr-5 mt-1.5'>
          <img
            tw='object-contain flex-shrink-0'
            alt='Location of earthquake'
            title='Jump to location' // TODO: to translations
            style={{ cursor: 'pointer', maxWidth: '85px' }}
            src={imageUrl}
            loading='lazy'
          />
        </div>
        <div tw='flex-grow'>
          <div>
            <span tw='mr-2 font-medium'>{t('Earthquakes.Sidebar.LocationText')}</span>
            <span>
              {earthquake.latitude.toFixed(2)}, {earthquake.longitude.toFixed(2)}
            </span>
          </div>
          <div tw='flex flex-col mb-3'>
            <div>
              <span tw='mr-2 font-medium'>{t('Earthquakes.Sidebar.TimeText')}</span>
            </div>
            <span>
              {new Date(earthquake.datetime).toLocaleString()} UTC
              {earthquake.datetime_local && (
                <>
                  <br />
                  {new Date(earthquake.datetime_local).toLocaleString()}{' '}
                  {earthquake.timezone as string}
                </>
              )}
            </span>
          </div>
          <div tw='flex justify-end'>
            <div onClick={handleReportButtonClick} tw='px-0 py-0 pr-2 cursor-pointer'>
              <FreeFeature>
                {t('Earthquakes.Sidebar.ViewFreeReportButtonText')}&nbsp;&nbsp;&gt;
              </FreeFeature>
              <NonFreeFeature>
                {t('Earthquakes.Sidebar.ViewReportButtonText')}&nbsp;&nbsp;&gt;
              </NonFreeFeature>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarthquakeItem
