import { Select } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { useTranslationFunction } from '../../../hooks/useTranslationFunction'
import { SortType } from '../../../lib/types/SortType'
import useEarthquakesStore from '../../../state/Earthquakes/EarthquakesStore'

const EarthquakesSortSelect = () => {
  const { sort, sortType } = useEarthquakesStore()
  const { t } = useTranslationFunction()

  const handleSortTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSortValue = event.target.value
    sort(newSortValue)
  }

  return (
    <Select
      value={sortType}
      onChange={handleSortTypeChange}
      onLoad={handleSortTypeChange}
      rounded='none'
    >
      <option value={SortType.DateAsc}>
        {t('Earthquakes.Sidebar.Sorting.EarlierDateFirstOption')}
      </option>
      <option value={SortType.DateDsc}>
        {t('Earthquakes.Sidebar.Sorting.LatestDateFirstOption')}
      </option>
      <option value={SortType.MagnitudeAsc}>
        {t('Earthquakes.Sidebar.Sorting.SmallestMagnitudeFirstOption')}
      </option>
      <option value={SortType.MagnitudeDsc}>
        {t('Earthquakes.Sidebar.Sorting.LargestMagnitudeFirstOption')}
      </option>
    </Select>
  )
}

export default EarthquakesSortSelect
