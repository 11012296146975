import React from 'react'
import { isExpired } from 'react-jwt'
import useAuthStore from '../../state/Auth/AuthStore'

interface Props {
  children: React.ReactNode
}

// Duplication: Opposite of NonFreeFeature
const FreeFeature: React.FC<Props> = ({ children }: Props) => {
  const { jwtToken } = useAuthStore()

  // FIXME: temporary auth bypass - re-enable isExpired check, see also: AuthStore
  // if (jwtToken || ???isExpired(jwtToken)) {
  if (jwtToken) {
    return null
  }

  return <>{children}</>
}

export default FreeFeature
