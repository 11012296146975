import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { defaultsDataState } from '../../lib/config/defaults-data-state'
import { Report } from '../../lib/interfaces/Report'
import { search } from '../../lib/services/search.service'
import { SortType } from '../../lib/types/SortType'
import {
  handleStateAfterSuccessfulHttpRequest,
  handleStateAfterUnsuccessfulHttpRequest,
  handleStateBeforeHttpRequest,
} from '../state-helper'
import { BaseDataState } from '../../lib/interfaces/BaseDataState'
import { Sortable } from '../../lib/interfaces/Sortable'
import { fetchReports } from '../../lib/services/old.report.service'
import { sortByType } from '../../lib/services/sorting.service'

interface ReportsState extends BaseDataState<Report> {
  dataOriginal: Report[]
  isSidebarOpen: boolean
  sortType: SortType | string
  toggleSidebar: (desiredState?: boolean) => void
  getAll: (firstDate: Date, lastDate: Date) => void
  sort: (sortType: SortType) => void
  search: (searchTerm: string) => void
  reset: () => void
}

const initialState = {
  ...defaultsDataState,
  dataOriginal: [],
  dataFiltered: [],
  isSidebarOpen: false,
  sortType: '',
}

const useReportsStore = create<ReportsState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      toggleSidebar: (desiredState?: boolean) => {
        // Duplication: EarthquakeStore
        const isSidebarOpen = get().isSidebarOpen
        let newIsSidebarOpen = !isSidebarOpen
        if (typeof desiredState === 'boolean') {
          newIsSidebarOpen = desiredState
        }
        if (newIsSidebarOpen !== isSidebarOpen) {
          set(() => ({
            isSidebarOpen: newIsSidebarOpen,
          }))
        }
      },
      getAll: async (firstDate: Date, lastDate: Date) => {
        handleStateBeforeHttpRequest(set)

        try {
          const response = await fetchReports(firstDate, lastDate)
          handleStateAfterSuccessfulHttpRequest(set)
          set({ data: response.data })
          set({ dataOriginal: response.data })
        } catch (error) {
          handleStateAfterUnsuccessfulHttpRequest(set)
        }
      },
      sort: (sortType: SortType | string) => {
        if (sortType === '') {
          set({ data: get().dataOriginal })
        } else {
          const reportsSorted = sortByType(get().data, sortType as SortType)
          set({ data: reportsSorted as Report[] })
        }
        set({ sortType: sortType })
      },
      search: (searchTerm: string) => {
        if (searchTerm !== '') {
          const searchResult = search(get().dataOriginal, searchTerm)
          const searchResultSorted = sortByType(
            searchResult as Sortable[],
            get().sortType as SortType,
          )
          set({ data: searchResultSorted as Report[] })
        } else {
          if (get().sortType === '') {
            set({ data: get().dataOriginal })
          } else {
            const reportsSorted = sortByType(get().dataOriginal, get().sortType as SortType)
            set({ data: reportsSorted as Report[] })
          }
        }
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: 'reports-storage',
    },
  ),
)

export default useReportsStore
