import { useEffect } from 'react'
import { AlertNotification } from '../../../lib/interfaces/AlertNotification'
import useAlertsNotificationsStore from '../../../state/AlertsNotifications/AlertsNotificationsStore'
import useDateStore from '../../../state/Date/DateStore'
import { withConditionalFeedback } from '../../Shared/withConditionalFeedback'
import AlertNotificationItem from './AlertNotificationItem'

const AlertsNotificationsList = () => {
  const { data, loading, hasErrors, getAll } = useAlertsNotificationsStore()
  const { firstDate, lastDate } = useDateStore()

  useEffect(() => {
    getAll(firstDate, lastDate)
  }, [])

  return (
    <BaseAlertsNotificationsListwithConditionalFeedback
      data={data}
      isLoading={loading}
      hasErrors={hasErrors}
    />
  )
}

interface BaseAlertsNotificationsListProps {
  data: AlertNotification[]
  isLoading: boolean
  hasErrors: boolean
}

const BaseAlertsNotificationsList = (props: BaseAlertsNotificationsListProps) => {
  return (
    <div>
      {props.data.map((alertNotification: AlertNotification) => (
        <AlertNotificationItem key={alertNotification.id} {...alertNotification} />
      ))}
    </div>
  )
}

const BaseAlertsNotificationsListwithConditionalFeedback = withConditionalFeedback(
  BaseAlertsNotificationsList,
)

export default AlertsNotificationsList
