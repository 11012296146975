import { LayerProps } from 'react-map-gl/maplibre'
import { MapLayerType } from '../../../../lib/types/MapLayerType'
import { circleRadiusKm } from '../../utils'
import { GeoJSONSourceSpecification } from 'maplibre-gl'

export const infrastructureLayers: readonly MapLayerType[] = [
  MapLayerType.Dams,
  MapLayerType.NuclearPowerPlants,
  MapLayerType.StockExchanges,
  MapLayerType.CountryStats,
]

export const infrastructureSourceProps = (): Omit<GeoJSONSourceSpecification, 'data'> => ({
  type: 'geojson',
  cluster: false,
})

export const infrastructureLayerProps = (id: MapLayerType, size: number): LayerProps => ({
  id: id,
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon-id'],
    // TODO: write the fancy scaler in utils 'icon-size': ['get', 'icon-size'],
    'icon-size': circleRadiusKm(size, 2.5, 5),
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-opacity': 0.75,
  },
})
