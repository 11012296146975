import { useTranslationFunction } from '../../hooks/useTranslationFunction'
import QuantectumLogoSmall from '../../assets/images/logo-full-white.png'

const LogoSmall = () => {
  const { t } = useTranslationFunction()

  return <img src={QuantectumLogoSmall} alt={t('Shared.Logo.Alt')} tw='w-[162px] h-[25px] mx-5' />
}

export default LogoSmall
