import { useEffect } from 'react'
import useNamedEventHandlersStore, {
  EventHandler,
} from '../state/EventHandlers/NamedEventHandlersStore'

const useNamedEventHandler = (name: string, handler: EventHandler): void => {
  const { addHandler, removeHandler } = useNamedEventHandlersStore()

  useEffect(() => {
    addHandler(name, handler)
    return () => {
      removeHandler(name, handler)
    }
  }, [name, addHandler, removeHandler])
}

export default useNamedEventHandler
