import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Route, Routes } from 'react-router-dom'
import AppLayout from './components/Layout/AppLayout'
import AuthLayout from './components/Layout/AuthLayout'
import { useTranslationFunction } from './hooks/useTranslationFunction'
import ConfirmAccountPage from './pages/ConfirmAccountPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import ProfilePage from './pages/ProfilePage'
import ResetPasswordPage from './pages/ResetPasswordPage'

const App = () => {
  const { t } = useTranslationFunction()

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('Shared.App.HeadTitle')}</title>
      </Helmet>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path='/' element={<MainPage />} />
          <Route path='/profile' element={<ProfilePage />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/forgotPassword' element={<ForgotPasswordPage />} />
          <Route path='/resetPassword' element={<ResetPasswordPage />} />
          <Route path='/confirmAccount' element={<ConfirmAccountPage />} />
        </Route>
      </Routes>
    </HelmetProvider>
  )
}

export default App
