import { extendTheme } from '@chakra-ui/react'
import { CalendarDefaultTheme } from '@uselessdev/datepicker'

export const globalTheme = extendTheme({
  fonts: {
    heading: '\'ApercuPro\', sans-serif', // prettier-ignore
    body: '\'ApercuPro\', sans-serif', // prettier-ignore
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 0,
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderRadius: 0,
          },
        },
      },
    },
    Popover: {
      parts: ['content'],
      baseStyle: {
        content: {
          borderRadius: 0,
        },
      },
    },
    Menu: {
      parts: ['list'],
      baseStyle: {
        list: {
          borderRadius: 0,
        },
      },
    },
    Drawer: {
      variants: {
        nonModal: {
          parts: ['dialog, dialogContainer'],
          dialog: {
            pointerEvents: 'auto !important',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
    Switch: {
      // The parts of the component
      parts: ['container', 'thumb', 'track'],
      // The base styles for each part
      baseStyle: {
        thumb: {
          bg: '#3A3A3A',
          _checked: {
            bg: '#005FDC',
          },
        },
        track: {
          bg: 'white',
          _checked: {
            bg: 'white',
          },
        },
      },
      // The size styles for each part
      sizes: {},
      // The variant styles for each part
      variants: {},
      // The default `size` or `variant` values
      defaultProps: {},
    },
  },
  colors: {
    quantectum: {
      blue: '#00194B',
      blueDark: '#001540',
      blueLight: '#005FDC',
      gray: '#d8d8d8',
      authGray: '#E5E5E5',
      whiteGray: '#F3F3F3',
      black: '#000000',
    },
  },
})

export const calendarTheme = extendTheme(CalendarDefaultTheme, {
  components: {
    Calendar: {
      parts: ['calendar'],
      baseStyle: {
        calendar: {
          rounded: 'none',
          shadow: 'none',
        },
      },
    },

    CalendarDay: {
      baseStyle: {
        // common styles
      },
      variants: {
        baseStyle: {
          bgColor: '#005FDC',
          _hover: {
            bgColor: '#00194B',
          },
          _focus: {
            bgColor: '#005FDC',
          },
        },
        selected: {
          // selected day styles
          bgColor: '#005FDC',
          _focus: {
            bgColor: '#005FDC',
          },
          _hover: {
            bgColor: '#005FDC',
          },
        },
        // Add more variants as needed
      },
      defaultProps: {
        variant: 'plain',
      },
    },

    CalendarControl: {
      parts: ['button'],
      baseStyle: {
        button: {
          h: 6,
          px: 2,
          rounded: 'none',
          fontSize: 'sm',
          color: 'white',
          bgColor: '#00194B',

          _hover: {
            bgColor: '#005FDC',
          },

          _focus: {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customChakraTheme = extendTheme(globalTheme, calendarTheme)
