import { Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useForgotPasswordState } from '../../hooks/useForgotPasswordState'
import { ForgotPasswordFormValues } from '../../lib/interfaces/ForgotPasswordFormValues'
import { QuantectumButtonStyles } from '../../styles/shared/QuantectumButtonStyles'
import LoadingFeedback from '../Shared/LoadingFeedback'
import ArrowBackIcon from '../../assets/images/arrow-back-icon.png'

const ForgotPasswordForm = () => {
  const { isLoading, handleBackClick, handleOnSubmit, t } = useForgotPasswordState()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordFormValues>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = async (
    formData: ForgotPasswordFormValues,
  ) => {
    handleOnSubmit(formData, true)
  }

  return (
    <form tw='relative' onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback isLoading={isLoading}>
        {() => {
          return (
            <>
              <div tw='text-center text-[18px] font-bold mb-[24px]'>
                {t('Auth.ForgotPasswordForm.Title')}
              </div>
              <FormControl id='email' isInvalid={errors.email !== undefined}>
                <FormLabel fontWeight='normal'>
                  {t('Auth.ForgotPasswordForm.EmailFormLabel')}
                </FormLabel>
                <Input type='email' {...register('email', { required: true })} />
                <FormErrorMessage>
                  {t('Auth.ForgotPasswordForm.EmailRequiredMessage')}
                </FormErrorMessage>
              </FormControl>
              <div tw='flex items-center justify-between mt-8'>
                <Button
                  onClick={handleBackClick}
                  leftIcon={<img src={ArrowBackIcon} />}
                  textColor='black'
                  bg='transparent'
                  fontWeight='normal'
                  _hover={{ bg: 'transparent' }}
                  _active={{ bg: 'transparent' }}
                >
                  {t('Auth.ForgotPasswordForm.BackButtonText')}
                </Button>
                <Button
                  {...QuantectumButtonStyles}
                  disabled={!isValid}
                  type='submit'
                  w='full'
                  ml={7}
                  py={7}
                >
                  {t('Auth.ForgotPasswordForm.ResetPasswordButtonText')}
                </Button>
              </div>
            </>
          )
        }}
      </LoadingFeedback>
    </form>
  )
}

export default ForgotPasswordForm
