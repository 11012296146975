import { LayerProps, SourceProps } from 'react-map-gl/maplibre'
import { MapLayerType } from '../../../../lib/types/MapLayerType'

export const rasterLayers: readonly MapLayerType[] = [
  MapLayerType.TectonicTraction,
  MapLayerType.ShearStress,
  MapLayerType.SmoothedSeismicity,
  MapLayerType.DynamicSeismicPotential,
  MapLayerType.AverageSeismicPotential,
  MapLayerType.ZonesIndonesia,
  MapLayerType.GeologyIndonesia,
]
export const freeRasterLayers: readonly MapLayerType[] = [
  MapLayerType.TectonicTraction,
  MapLayerType.ShearStress,
  MapLayerType.SmoothedSeismicity,
  MapLayerType.DynamicSeismicPotential,
  MapLayerType.AverageSeismicPotential,
]

export const forecastsLayers = rasterLayers
export const freeForecastsLayers = freeRasterLayers

export const rasterSourceProps = (id: MapLayerType, tileUrl: string): SourceProps => ({
  id: id,
  type: 'raster',
  tileSize: 1399,
  minzoom: 0,
  maxzoom: 0,
  tiles: [tileUrl],
})

export const rasterLayerProps = (id: MapLayerType): LayerProps => ({
  id: id,
  source: id,
  type: 'raster',
  paint: {
    'raster-opacity': 0.75,
    'raster-fade-duration': 0,
  },
})
